<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card v-if="projectData.length > 0">
            <!-- VENDORS -->
              <template slot="title">
                <!-- <i class="fa fa-user"></i> Vendors -->
              </template>
              <b-row>
                <b-col md="12">
                  <!-- <h4 class="d-inline">Vendors</h4> -->
                 <div class="form-group ml-2" style="width: 200px; float: right; =">
                    <select @change="changeVendors(project_selected)" style="border: 2px solid #c1c1c1;" class="form-control" v-model="project_selected" id="vendorFilter">
                      <option value="all">all</option>
                       <option v-for="(v, i) in projectData" :value="v.projectid">{{v.projectname}}</option>
                    </select>
                  </div>

                  <div class="form-group ml-2" style="width: 200px; float: right; =">
                    <select @change="changeVendors(0)" style="border: 2px solid #c1c1c1;" class="form-control" v-model="vendorChange" id="vendorFilter">
                      <option selected value="all">All Vendors Registered</option>
                      <option value="used">Vendors with services</option>
                      <option value="unused">Vendors without services</option>
                    </select>
                  </div>

                  <vue-json-to-csv :labels="{
                    email: { title: 'Email' },
                    fname: { title: 'First Name' },
                    lname: { title: 'Last Name' },
                    company_name: { title: 'Company Name' },
                    creation_date: { title: 'Registration Date'}
                  }" v-if="$refs.vendorTable" :csv-title="'Vendor_Export'" class="btn float-right ml-3 btn-md btn-blue"
                      :json-data="$refs.vendorTable.allFilteredData">
                      Download Table CSV/Excel
                      <i class="fa fa-download"></i>
                  </vue-json-to-csv>
                </b-col>

              </b-row>

              <v-client-table ref="vendorTable" class="statusTable" :columns="columnsVendorServices" :data="wtsVendorList" :options="optionsVendorServices" :theme="theme" id="dataTable">

                <div slot="status" slot-scope="props">
                  <button class="btn btn-sm btn-success w-100">Active</button>
                </div>
                <div slot="fname" slot-scope="props">
                  {{props.row.fname}} {{props.row.lname}}
                </div>
                <div slot="company_name" slot-scope="props">
                  {{getVendorCompanyName(props.row.vid)}}
                </div>
                <div slot="creation_date" slot-scope="props">{{props.row.creation_date | moment('MM-DD-YYYY hh:mm A')}}</div>

                <div slot="services" slot-scope="props">
                  <b-button variant="primary" class="btn-sm" @click="loadVendorServices(props.row.vid)"><i class="fa fa-eye"></i> View Assigned Services</b-button>
                </div>
                <div slot="edit" slot-scope="props">
                  <b-button v-b-modal.editVendorEmail @click="sendVendorId(props.row.vid, props.row.email)" class="btn-sm" variant="success">Edit Email <i class="fa fa-edit"></i></b-button>
                </div>

                  <div slot="delete" slot-scope="props">
                  
                  <b-button @click="DeleteVendor(props.row.vid,props.row.email)" v-b-modal.modal-10 class="btn-sm" variant="danger"><i class="fa fa-trash"></i></b-button>
                </div>

              </v-client-table>

      <b-progress :max="serviceLength" class="mb-3" height="1.5rem" style="font-size:0.8rem; width: 300px; float: right;" show-value animated v-if ="serviceLength > 0">
      <b-progress-bar :value="DeletedService" :label="'Progress: '+ DeletedService + '/' + serviceLength" variant="success"></b-progress-bar>
     </b-progress><br />

      <b-modal @shown="clearErrors()" id="addService" ref="addService" title="Add Service" title-tag="h2" centered size="lg" hide-footer>
        <form @submit.prevent="addServiceValidator('addService')" data-vv-scope="addService" class="pb-1">
          <b-row class="mb-3">
             <b-col md="12">
          <h5>Select Project</h5>
          <multiselect v-model="projectid" placeholder="Search For Project" tag-placeholder="Search For Project" track-by="projectid" :options="projectData" :multiple="false" :close-on-select="true" :preserve-search="true" :taggable="true" class="w-100" label="projectname" :preselect-first="true">
          </multiselect>
        </b-col>

            <b-col md="12" class="mb-1">
              <label for="">Service Name: </label>
              <b-input-group class="mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-hashtag"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" v-validate="'required'" name="name" v-model="name" class="form-control" placeholder="Service Name" />
              </b-input-group>
              <i v-show="errors.has('addService.name')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('addService.name')">The service name is required.</span>
            </b-col>
            <b-col md="12" class="mb-1">
              <label for="">Service Code: </label>
              <b-input-group class="mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-hashtag"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" v-validate="'required'" name="type" v-model="type" class="form-control" placeholder="Service Code" />
              </b-input-group>
              <i v-show="errors.has('addService.type')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('addService.type')">The service code is required.</span>
            </b-col>

            <b-col cols="6" class="mb-1">
              <label for="">UOM: <i class="fa fa-question-circle" v-b-tooltip.hover title="Unit Of Measurement"></i></label>
              <b-input-group class="mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-hashtag"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" v-validate="'required'" name="uom1" v-model="uom1" class="form-control" placeholder="" />
              </b-input-group>
              <i v-show="errors.has('addService.uom1')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('addService.uom1')">The unit of measurement required.</span>
            </b-col>
            <b-col cols="6" class="mb-1">
              <label for="">Price / Unit: </label>
              <b-input-group class="mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" v-validate="'required|decimal:3'" name="pu1" v-model="pu1" class="form-control" placeholder="" />
              </b-input-group>
              <i v-show="errors.has('addService.pu1')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('addService.pu1')">The price is required. Numbers only. Up to 3 decimals only.</span>
            </b-col>

            <b-col cols="12" class="mb-1" v-if="uom2option === 0">
              <a href="javascript:void(0)" @click="uom2option = 1" class="btn mt-2 float-right btn-success">Add Another UOM</a>
            </b-col>

            <b-col cols="6" class="mb-1" v-if="uom2option === 1">
              <label for="">UOM 2: </label>
              <b-input-group class="mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-hashtag"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" name="uom2" v-model="uom2" class="form-control" placeholder="" />
              </b-input-group>
            </b-col>
            <b-col cols="6" class="mb-1" v-if="uom2option === 1">
              <label for="">Price / Unit: </label>
              <b-input-group class="mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" v-validate="'decimal:3'" name="pu2" v-model="pu2" class="form-control" placeholder="" />
              </b-input-group>
              <i v-show="errors.has('addService.pu2')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('addService.pu2')"> Numbers only. Ex: $59.99</span>
            </b-col>
            <b-col cols="12" class="mb-1" v-if="uom2option === 1 && uom3option === 0">
              <a href="javascript:void(0)" @click="uom3option = 1" class="btn mt-2 float-right btn-success">Add Another UOM</a>
            </b-col>
            <b-col cols="6" class="mb-1" v-if="uom3option === 1">
              <label for="">UOM 3: </label>
              <b-input-group class="mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-hashtag"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" name="uom3" v-model="uom3" class="form-control" placeholder="" />
              </b-input-group>
            </b-col>
            <b-col cols="6" class="mb-1" v-if="uom3option === 1">
              <label for="">Price / Unit: </label>
              <b-input-group class="mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" v-validate="'decimal:3'" name="pu3" v-model="pu3" class="form-control" placeholder="" />
              </b-input-group>
              <i v-show="errors.has('addService.pu3')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('addService.pu3')">The price is required. Numbers only. Ex: $59.99</span>
            </b-col>
            <b-col cols="12" class="mb-2">
              <label for="">Currency</label>
              <b-input-group class="mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-select v-model="currency">
                  <option value="CAD" selected>CAD</option>
                  <option value="USD">USD</option>
                </b-select>
              </b-input-group>
              <i v-show="errors.has('addService.currency')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('addService.currency')">The price is required. Numbers only. Ex: $59.99</span>
            </b-col>
            <b-col md="12">
              <b-button variant="success" class='mt-2' type="submit" block>Create Service <i class="fa fa-plus"></i></b-button>
            </b-col>
          </b-row>
          <i v-show="error" class="fa fa-exclamation-triangle mt-3"></i><span v-show="error" class="help is-danger">Service error. Cannot have duplicates. Please contact admin.</span></span>
        </form>
      </b-modal>

              <b-modal id="editVendorEmail" ref="editVendorEmail" title="Edit Vendor Email" title-tag="h2" centered size="lg" hide-footer>
                <form @submit.prevent="emailvalidateBeforeSubmit('editemail')" class="pb-2">
                  <p class="text-muted">Enter new email.</p>
                  <b-row class="mb-3">
                    <b-col md="12">
                      <b-input-group class="mb-2">
                        <b-input-group-prepend>
                          <b-input-group-text><i class="fa fa-envelope"></i></b-input-group-text>
                        </b-input-group-prepend>
                        <input type="email" class="form-control" v-model="email" placeholder="Email Address" name="email" ref="email"/>
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <i v-show="emailerror" class="fa fa-exclamation-triangle"></i><span v-show="emailerror" class="help is-danger">Error. Please try again.</span>
                  <b-button variant="success" class="mt-4" type="submit" block>Edit Email</b-button>
                </form>
              </b-modal>

              <WtsImport id="scrollToServices" v-bind:update="update" v-bind:name="vcnProp" v-bind:vid="vid" v-bind:pid="project_selected"/>
    </b-card>
    <b-card v-else>
    <div>No Project Assigned.</div>
    </b-card>
     </b-col>
       </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { ServerTable, ClientTable, Event } from "vue-tables-2";
Vue.use(ClientTable);
import VueSimpleSuggest from "vue-simple-suggest";
import Datepicker from "vuejs-datepicker";
import multiSelect from "vue-multi-select";
import WtsImport from "./AllwtsImport";
import WtsChainImport from "./WtsChainImport";
import "vue-multi-select/dist/lib/vue-multi-select.min.css";
import Multiselect from "vue-multiselect";
import { RotateSquare2 } from "vue-loading-spinner";

import VueJsonToCsv from "vue-json-to-csv";
import Papa from "papaparse";
import Blob from "blob";
import FileSaver from "file-saver";
import promiseLimit from "promise-limit";

import VueRouter from "vue-router";

Vue.use(ServerTable);
Vue.use(VueRouter);
window.axios = require("axios");

export default {
  name: "WtsProject",
  components: {
    ServerTable,
    ClientTable,
    Event,
    VueSimpleSuggest,
    Datepicker,
    multiSelect,
    WtsImport,
    WtsChainImport,
    Multiselect,
    RotateSquare2,
    VueJsonToCsv,
  },
  props: ["resource"],
  data() {
    return {
      afeStyleObject: {
        margin: "5px",
      },
      searchBox: "",
      afeStyleObject2: {
        margin: "5px",
      },
      costCenters: [],
      budget: 0,
      cc1Text: "",
      cc1B: "",
      cc2B: "",
      cc3B: "",
      cc1BSelected: null,
      cc2BSelected: null,
      cc3BSelected: null,
      cc2Text: "",
      cc3Text: "",
      errorChain: "",
      update: "",
      addnewafenum: "",
      wtsVendorListAll: [],
      vendorListServiceEstimate: [],
      vendorListServiceDetailsServiceEstimate: [],
      vendorEstimatedServiceListServiceEstimate: [],
      wssOptions: {
        multi: true,
        renderTemplate(elem) {
          return `${elem.fname} ${elem.lname}`;
        },
      },
      wssCreateList: [],
      tree: [],
      theme: "bootstrap4",
      template: "default",
      useVuex: true,
      open: ["public"],
      //Wells Data Table
      columns: ["status", "uwi", "lsdsurface", "wellstatus", "edit"],
      options: {
        headings: {
          projectname: "Project Name",
          ptn: "Number",
          afes: "AFE",
          uwi: "UWI",
          wellstatus: "Status Of Well",
          lsdsurface: "Surface Location",
        },
        sortable: ["status", "uwi", "afes", "lsdsurface", "ptn"],
        filterable: ["status", "uwi", "afes", "lsdsurface", "ptn"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
      },
      emailList: [],
      //Email List Table
      emailListColumns: ["status", "mail", "creation_date"],
      emailListOptions: {
        headings: {
          mail: "Email",
          creation_date: "Invite Date",
        },
        sortable: ["mail", "creation_date"],
        filterable: ["mail", "creation_date"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
      },
      //Category Data Table
      columnsCat: ["status", "category", "description", "edit"],
      optionsCat: {
        headings: {},
        sortable: ["status", "category", "description"],
        filterable: ["status", "category", "description"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
      },
      //AFE Data Table
      columnsAfe: [
        "status",
        "afenum",
        "uwi",
        "category",
        "TotalBudget",
        "TotalCost_vendor",
        "totalNet",
        "cc",
        "edit",
      ],
      optionsAfe: {
        headings: {
          afenum: "AFE Number",
          uwi: "UWI (License Number)",
          cw_start_date: "Start Date",
          cw_end_date: "End Date",
          cc: "Enter Cost Codes",
          TotalBudget: "Total Budget",
          TotalCost_vendor: "Total Cost",
          totalNet: "Total Net",
        },
        sortable: [
          "status",
          "afenum",
          "uwi",
          "ln",
          "category",
          "projectname",
          "cw_start_date",
          "cw_end_date",
        ],
        filterable: [
          "status",
          "afenum",
          "uwi",
          "ln",
          "category",
          "projectname",
          "cw_start_date",
          "cw_end_date",
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
      },
      //Services Data Table
      columnsServices: [
        "status",
        "name",
        "type",
        "uom1",
        "uom2",
        "uom3",
        "currency",
        "actions",
      ],
      optionsServices: {
        headings: {
          type: "Code",
          uom1: "Price/UOM",
          uom2: "Price/UOM",
          uom3: "Price/UOM",
        },
        sortable: [
          "status",
          "id",
          "name",
          "type",
          "uom1",
          "uom2",
          "uom3",
          "currency",
          "afe",
        ],
        filterable: [
          "status",
          "id",
          "name",
          "type",
          "uom1",
          "uom2",
          "uom3",
          "currency",
          "afe",
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      //Services Data Table
      columnsCC1AssignedServices: [
        "code",
        "cctwo_code",
        "ccthree_code",
        "budget",
        "total",
        "net",
        "vendor_service",
      ],
      columnsCCVendorAssignedServices: ["vid"],
      columnsCCServiceAssignedServices: [
        "delete",
        "icon",
        "servicename",
        "servicebudget",
        "servicecost",
        "servicenet",
      ],
      optionsCC1AssignedServices: {
        headings: {
          code: "Cost Code 1",
          cctwo_code: "Cost Code 2",
          ccthree_code: "Cost Code 3",
          vendor_service: "Vendor/ Service",
          budget: "Budget",
          total: "Total Cost",
          net: "Net",
        },
        //  uniqueKey: "cconeid",
        sortable: ["id"],
        filterable: ["code", "cctwo_code", "ccthree_code", "company_name"],
        perPage: 40,
        editableColumns: ["budget"],
      },

      optionsCCVendorAssignedServices: {
        uniqueKey: "vid",
        perPage: 1000,
        filterable: false,
        headings: {
          vid: "Vendors",
        },
      },

      optionsCCServiceAssignedServices: {
        headings: {
          delete: "",
          icon: "",
          servicecost: "Actual",
          servicebudget: "Service Estimate",
          servicenet: "Difference",
        },
        uniqueKey: "servicename",
        perPage: 10,
        filterable: ["servicename"],
      },

      lastChildColumnsAssignedServices: ["serviceName", "total", "delete"],
      lastChildOptionsAssignedServices: {
        headings: {
          serviceName: "Service Name",
          total: "Total",
          delete: "Delete",
        },
        sortable: ["code", "name"],
        filterable: false,
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
        perPage: 10,
      },
      //WSS Data Table
      columnsWssServices: ["status", "fname", "email", "edit", "delete"],
      optionsWssServices: {
        headings: {
          fname: "Name",
          lname: "Last Name",
          delete: "Delete Wss",
        },
        sortable: [
          "status",
          "ccone_code",
          "cctwo_code",
          "ccthree_code",
          "serviceid",
        ],
        filterable: [
          "status",
          "ccone_code",
          "cctwo_code",
          "ccthree_code",
          "serviceid",
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
        perPage: 5,
      },
      columnsVendorServices: [
        "status",
        "company_name",
        "fname",
        "email",
        "creation_date",
        "vidcode",
        "services",
        "edit",
        "delete",
      ],
      optionsVendorServices: {
        headings: {
          fname: "Name",
          lname: "Last Name",
          vidcode: "Vendor ID Code",
          edit: "Edit Email",
          creation_date: "Registration Date",
          delete: "Delete Vendor",
        },
        sortable: [
          "status",
          "company_name",
          "creation_date",
          "fname",
          "services",
        ],
        filterable: ["status", "company_name", "fname", "services"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
        perPage: 40,
      },
      columnsVendorForServices: ["status", "name"],
      optionsVendorForServices: {
        headings: {
          name: "Service",
          edit: "Edit",
        },
        sortable: [
          "status",
          "ccone_code",
          "cctwo_code",
          "ccthree_code",
          "serviceid",
        ],
        filterable: false,
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
      },
      columnsCostCodeServices: [
        "costcode1",
        "costcode2",
        "costcode3",
        "optionaldescription",
        "edit",
      ],
      optionsCostCodeServices: {
        perPage: 20,
        pagination: {
          edge: false,
          dropdown: false,
          chunk: 10,
        },
        headings: {
          costcode1: "Cost Code 1",
          costcode2: "Cost Code 2",
          costcode3: "Cost Code 3",
          optionaldescription: "Optional Description",
          edit: "Action",
        },
        sortable: ["costcode1", "costcode2", "costcode3"],
        filterable: true,
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
        params: {
          Pid: this.$route.params.id,
        },
        requestKeys: {
          query: "query",
          limit: "limit",
          orderBy: "orderBy",
          ascending: "ascending",
          page: "page",
          byColumn: "byColumn",
        },
        requestFunction(data) {
          return this.$axios
            .get(`/wts/export/cost_code_chain/getCostCode/`, {
              params: data,
            })
            .catch((error) => {});
        },
        responseAdapter(response) {
          return {
            data: response.data.result.rows,
            count: response.data.result.count,
          };
        },
      },
      //Error Messages Set Blank
      error: false,
      status: null,
      sendIdModal: null,

      uom2option: 0,
      uom3option: 0,

      projectData: [],
      wtsAssignmentData: [],
      wtsWellData: [],
      wtsVendorServiceData: [],
      wtsCatData: [],
      wtsAfeData: [],
      cc1Data: [],
      cc2Data: [],
      cc3Data: [],
      chainData: [],
      categories: [],
      servicesData: [],
      wtsVendorList: [],
      serviceLength: 0,
      DeletedService: 0,
      wtsWssData: [],
      wtsWssListData: [],
      wtsVendorData: [],
      wtsVendorListData: [],
      cw_start_date: "",
      cw_end_date: "",
      id: 0,
      pwid: 0,
      pcid: 0,
      pid: 0,
      projectid: 0,
      description: "",
      category: "",
      cc1: [],
      cc2: [],
      cc3: [],
      afeCCOne: [],
      afeCCTwo: [],
      afeCCThree: [],
      vendorCompanyNameAFE: [],
      vendorChainTotalCostNew: 0,
      vendorChainBudgetNew: 0,
      vendorCompanyNameId: "",
      serviceEstimateControl: false,
      serviceEstimateControl1: false,
      serviceEstimateControl2: false,
      All_vendor_Services: [],
      ccOneID: 0,
      ccTwoID: 0,
      ccThreeID: 0,
      ccone: "",
      cctwo: "",
      ccthree: "",
      afenum: null,
      uwi: "",
      uwi2: "",
      uwi3: "",
      ln: "",
      lsdsurface: "",
      lsdbottom: "",
      lsd: "",
      sec: "",
      twp: "",
      rge: "",
      meridian: "",
      lsdBottom: "",
      secBottom: "",
      twpBottom: "",
      rgeBottom: "",
      meridianBottom: "",
      type: "",
      name: "",
      uom1: "",
      uom2: "",
      uom3: "",
      pu1: "",
      pu2: "",
      pu3: "",
      elid: 0,
      currency: "CAD",
      wellselect: "",
      surfaceLocation: "",
      cc1select: 0,
      cc2select: 0,
      cc3select: 0,
      afeselect: 0,
      serviceid: "",
      vendorid: "0",
      code: "",
      afenumber: null,
      editafenum: "",
      wssid: [],
      costCodes: [],
      ccPanel: 0,
      newCode: 1,
      newCode2: 1,
      newCode3: 1,
      allCC: [],
      selected: null,
      pwcaid: "",
      afenums: [],
      lines: [],
      blockRemoval: true,
      service: null,
      cconeid: 0,
      afeDateNumber: 0,
      startOrEndNumber: 0,
      is_default_afe: "",
      table: 0,
      vid: 0,
      vcnProp: "",
      email: "",
      oldAfenum: null,
      anotherProjectid: [],
      anotherAfe: [],
      anotherAfeData: [],
      loadingImport: false,
      vendor: [],
      vendorServices: [],
      vendorSelectedServices: [],
      profile: [],
      addNewCostName: "",
      costCodeType: 0,
      uom1: "",
      uom2: "",
      uom3: "",
      uom1Quantity: 0,
      uom2Quantity: 0,
      uom3Quantity: 0,
      serviceid: 0,
      loading: false,
      serviceData: [],
      projectidForRouter: 5,
      labels: {
        name: { title: "Name" },
        uom1: { title: "UOM 1" },
        estimateQuantity1: { title: "Estimate Quantity 1" },
        uom2: { title: "UOM 2" },
        estimateQuantity2: { title: "Estimate Quantity 2" },
        uom3: { title: "UOM 3" },
        estimateQuantity3: { title: "Estimate Quantity 3" },
      },
      editedLine: [],
      editedLineName: "",
      beforeEditCacheCatch: 0,
      doc: [],
      parseCsv: [],
      successServices: [],
      serviceErrors: [],
      afeData: [],
      afes: [],
      vendorEstimateData: [],
      vendorEstimates: [],
      estimateServicesData: [],
      estimateNoTotalServicesData: [],
      vendorChange: "all",
      project_selected: "all",
      wellChange: false,
      locEx: "00",
      qtr: "",
      unit: "",
      blk: "",
      map: "",
      msd: "",
      sht: "",
      seq: "0",
      locExBottom: "00",
      qtrBottom: "",
      unitBottom: "",
      blkBottom: "",
      mapBottom: "",
      msdBottom: "",
      shtBottom: "",
      seqBottom: "0",
      loadingCount: 0,
      progressBarServicesCount: 0,
      progressBar: false,
      importNewVendorServices: false,
      deletingAllAssignments: false,
      oldemail: "",
      emailerror: false,
      vendorCompanyServiceSize: 0,
      testSampleData: [],

      afeDetailsTableRaj: ["servicename", "servicecost", "vendorTotal"],
      afeDetailsTableDataRaj: [],
      afeDetailsTableOptionsRaj: {},
      childRowCC1: 0,
      childRowCC2: 0,
      childRowCC3: 0,
      childRowVendor: 0,

      projectidForBudget: 0,
      companyidForBudget: 0,
      pwcaidForBudget: 0,
      cconeidForBudget: 0,
      cctwoidForBudget: 0,
      ccthreeidForBudget: 0,
      totalestimates: 0,
      openFlag: false,
      closeFlag: true,
      pwcaidForReload: 0,
      VendorServiceEstimateData: [],
      costCodeData: [],
      costcode1: "",
      costcode2: "",
      costcode3: "",
      cc1_code: "",
      cc2_code: "",
      cc3_code: "",
      edit_costcode1: "",
      edit_costcode2: "",
      edit_costcode3: "",
      edit_optionaldescription: "",
      optionaldescription: "",
      rowsCC: "",
      perPageCC: 20,
      limit: 20,
      offset: 0,
      search_val: 0,
      allCostCodes: [],
      totalBudget: 0,
      actualTotal: 0,
      totalnet: 0,
      toggle: true,
      totalServiceEstimate: 0,
      TotalActual: 0,
      Difference: 0,
    };
  },
  methods: {
    DeleteVendor(vid, email) {
      if (this.project_selected == "all") {
        this.$toasted.show("Please Select Project.", {
          type: "info",
          duration: "3000",
        });
        return;
      }

      this.emailList.map((data) => {
        if (
          data.mail === email &&
          data.role == 0 &&
          data.pid == this.project_selected
        ) {
          this.elid = data.elid;
        }
      });

      this.$http
        .delete(
          "/wts/delete/vendors/" +
            vid +
            "/" +
            this.project_selected +
            "/" +
            this.elid
        )
        .then((res) => {
          this.$http
            .get("/wts/get/services/" + this.project_selected)
            .then((response) => {
              var services = response.data.result;

              if (services.length > 0) {
                this.$http
                  .get("/wts/get/vas/" + this.project_selected)
                  .then((response) => {
                    var test = response.data.result;
                    var vas_data = test.filter((x) => x.vid === vid);

                    if (vas_data.length > 0) {
                      this.serviceLength = vas_data.length;
                      vas_data.forEach((v, i) => {
                        this.$http
                          .delete(
                            "/wts/delete/vas/" +
                              v.serviceid +
                              "/" +
                              vid +
                              "/" +
                              this.project_selected
                          )
                          .then((res) => {
                            this.DeletedService = this.DeletedService + 1;
                            if (vas_data.length - 1 == i) {
                              this.serviceLength = 0;
                              this.DeletedService = 0;
                              this.$toasted.show(
                                "Vendor deleted Succesfully.",
                                { type: "success", duration: "3000" }
                              );
                              this.$toasted.show(
                                "Services deleted succesfully.",
                                { type: "success", duration: "3000" }
                              );
                              this.$router.go();
                            }
                          })
                          .catch((err) => {});
                      });
                    }
                  })
                  .catch((error) => {});
              } else {
                this.$toasted.show("Vendor deleted Succesfully.", {
                  type: "success",
                  duration: "3000",
                });
                this.loadData();
              }
            })
            .catch((error) => {});
        })
        .catch((err) => {
          this.$toasted.show("Vendor is in used.", {
            type: "error",
            duration: "3000",
          });
        });
    },

    deleteWss(props) {
      this.emailList.map((data) => {
        if (data.mail === props.email && data.role == 1) {
          this.elid = data.elid;
        }
      });

      this.$http
        .delete(
          "/wts/delete/wss/" +
            this.$route.params.id +
            "/" +
            props.wssid +
            "/" +
            this.elid
        )
        .then((res) => {
          this.$toasted.show("Wss deleted Succesfully.", {
            type: "success",
            duration: "3000",
          });
          this.loadData();
        })
        .catch((err) => {
          this.$toasted.show("Wss is in used.", {
            type: "error",
            duration: "3000",
          });
        });
    },

    sendVendorId(vid, email) {
      this.vid = vid;
      this.email = email;
      this.oldemail = email;
    },
    emailvalidateBeforeSubmit() {
      let data = JSON.stringify({
        vid: this.vid,
        oldemail: this.oldemail,
        newemail: this.email,
      });
      this.$http
        .post("/vendor/edit/email", data)
        .then((response) => {
          this.loadData();
          this.$toasted.show("Email Updated", {
            icon: "fa-check",
            type: "success",
            duration: "3000",
          });
          this.$refs.editVendorEmail.hide();
          this.email = "";
          this.emailerror = false;
        })
        .catch((error) => {
          this.emailerror = true;
        });
      return;
    },
    clearWellFields() {
      this.lsdsurface = "";
      this.lsdbottom = "";
      this.lsd = "";
      this.sec = "";
      this.twp = "";
      this.rge = "";
      this.meridian = "";
      this.lsdBottom = "";
      this.secBottom = "";
      this.twpBottom = "";
      this.rgeBottom = "";
      this.meridianBottom = "";
      this.locEx = "";
      this.qtr = "";
      this.unit = "";
      this.blk = "";
      this.map = "";
      this.msd = "";
      this.sht = "";
      this.seq = "0";
      this.locExBottom = "";
      this.qtrBottom = "";
      this.unitBottom = "";
      this.blkBottom = "";
      this.mapBottom = "";
      this.msdBottom = "";
      this.shtBottom = "";
      this.seqBottom = "0";
    },
    changeVendors(pid) {
      if (this.vendorChange == "all") {
        this.$http
          .get("/wts/get/vendors/" + this.project_selected)
          .then((response) => {
            this.wtsVendorList = response.data.result;
          })
          .catch((error) => {});
      }
      if (this.vendorChange == "used") {
        this.$http
          .get("/wts/get/vendors/" + this.project_selected)
          .then((response) => {
            this.wtsVendorList = response.data.result;

            this.$http
              .get("/wts/get/vas/" + this.project_selected)
              .then((response) => {
                this.wtsVendorList = response.data.result;
                this.wtsVendorList = this.lodash.intersectionBy(
                  response.data.result,
                  this.wtsVendorList,
                  "vid"
                );
              });
          })
          .catch((error) => {});
      }
      if (this.vendorChange == "unused") {
        this.$http
          .get("/wts/get/vendors/" + this.project_selected)
          .then((response) => {
            this.wtsVendorList = response.data.result;
            this.$http
              .get("/wts/get/vas/" + this.project_selected)
              .then((response) => {
                this.wtsVendorList = this.lodash.differenceBy(
                  this.wtsVendorList,
                  response.data.result,
                  "vid"
                );
              });
          })
          .catch((error) => {});
      }
    },
    getVendorsForEstimates() {
      this.serviceData = this.estimateServicesData.filter(
        (x) => x.vendor_company_name == this.vendorEstimates.company_name
      );
      this.getNoTotalServices(this.serviceData);
    },
    sendFiltered() {
      var filtered = rows.map(function (i) {
        i.name = i.name;

        var uom2 = "";
        var uom3 = "";

        var pu2 = "";
        var pu3 = "";

        if (i.groupedUom[0]) {
          i.uom1 = i.groupedUom[0].name;

          if ("estimateQuantity1" in i.groupedUom[0]) {
            i.estimateQuantity1 = i.groupedUom[0].estimateQuantity1;
          } else {
            i.estimateQuantity1 = 0;
          }
        }
        if (i.groupedUom[1]) {
          uom2 = i.groupedUom[1].name;

          if ("estimateQuantity2" in i.groupedUom[0]) {
            pu2 = i.groupedUom[0].estimateQuantity2;
          }
        }
        if (i.groupedUom[2]) {
          uom3 = i.groupedUom[2].name;

          if ("estimateQuantity3" in i.groupedUom[0]) {
            pu3 = i.groupedUom[0].estimateQuantity3;
          }
        }

        i.uom2 = uom2;
        i.uom3 = uom3;
        i.estimateQuantity2 = pu2;
        i.estimateQuantity3 = pu3;

        return i;
      });

      for (var i = 0; i < this.servicesNoTotals.length; i++) {
        var est1 = 0;
        var est2 = 0;
        var est3 = 0;
        var uom1 = " ";
        var uom2 = " ";
        var uom3 = " ";

        if (this.servicesNoTotals[i].estimate) {
          if (this.servicesNoTotals[i].estimate.qty1) {
            est1 = this.servicesNoTotals[i].estimate.qty1;
          }
          if (this.servicesNoTotals[i].estimate.qty2) {
            est2 = this.servicesNoTotals[i].estimate.qty2;
          }
          if (this.servicesNoTotals[i].estimate.qty3) {
            est3 = this.servicesNoTotals[i].estimate.qty3;
          }
        }

        if (this.servicesNoTotals[i].uom1) {
          uom1 = this.servicesNoTotals[i].uom1;
        }
        if (this.servicesNoTotals[i].uom2) {
          uom2 = this.servicesNoTotals[i].uom2;
        }
        if (this.servicesNoTotals[i].uom3) {
          uom3 = this.servicesNoTotals[i].uom3;
        }

        filtered.push({
          name: this.servicesNoTotals[i].name,
          uom1: uom1,
          uom2: uom2,
          uom3: uom3,
          estimateQuantity1: est1,
          estimateQuantity2: est2,
          estimateQuantity3: est3,
        });
      }

      return filtered;
    },
    editEstimate(service) {
      var qty1 = 0;
      var qty2 = 0;
      var qty3 = 0;
      if (service.groupedUom[0]) {
        qty1 = service.groupedUom[0].estimateQuantity1;
      }

      if (service.groupedUom[1]) {
        qty2 = service.groupedUom[1].estimateQuantity2;
      }

      if (service.groupedUom[2]) {
        qty3 = service.groupedUom[2].estimateQuantity3;
      }

      let data = JSON.stringify({
        service_estimateid: service.services[0].estimate.service_estimateid,
        qty1: qty1,
        qty2: qty2,
        qty3: qty3,
      });

      this.$axios.post("service_estimate/edit/", data).then((response) => {
        this.editedLine = [];
        this.editedLineName = [];
        this.beforeEditCacheCatch = 0;

        this.getServices(true);
      });
    },
    editNoEstimate(service) {
      var qty1 = 0;
      var qty2 = 0;
      var qty3 = 0;

      if (service.estimate) {
        if (service.estimate.qty1) {
          qty1 = service.estimate.qty1;
        }
        if (service.estimate.qty2) {
          qty2 = service.estimate.qty2;
        }
        if (service.estimate.qty3) {
          qty3 = service.estimate.qty3;
        }
      }

      let data = JSON.stringify({
        service_estimateid: service.estimate.service_estimateid,
        qty1: qty1,
        qty2: qty2,
        qty3: qty3,
      });

      this.$axios.post("service_estimate/edit/", data).then((response) => {
        this.editedLine = [];
        this.editedLineName = "";
        this.beforeEditCacheCatch = 0;

        //this.getServices(true);
      });
    },
    Reload_getVendorFromAfeForServiceEstimate(reset) {
      var url =
        "/service_estimate/get/vendor/project/" +
        this.$route.params.id +
        "/afe/" +
        this.pwcaidForReload;

      this.$axios.get(url).then((response) => {
        this.vendorListServiceEstimate = response.data;
        this.Reload_getVendorServiceForServiceEstimate();
      });
    },
    Reload_getVendorServiceForServiceEstimate() {
      this.vendorListServiceDetailsServiceEstimate = [];
      this.vendorListServiceDetailsServiceEstimate = [];

      var url =
        "/service_estimate/get/vendor/services/project/" +
        this.$route.params.id +
        "/vid/" +
        this.vendorEstimates.vid +
        "/afe/" +
        this.pwcaidForReload;

      this.$axios.get(url).then((response) => {
        this.vendorListServiceDetailsServiceEstimate =
          response.data.vendorservice;
        this.vendorEstimatedServiceListServiceEstimate =
          response.data.serviceestimate;
      });
    },
    editData(line, name) {
      if (this.beforeEditCacheCatch == 1 && this.editedLineName != name) {
        this.$toasted.show("Please save edit first", {
          type: "error",
          duration: "3000",
        });
        return;
      }
      this.editedLine = line;
      this.editedLineName = name;

      this.beforeEditCacheCatch = JSON.parse(JSON.stringify(1));
    },
    editNoData(line, name) {
      if (this.beforeEditCacheCatch == 1 && this.editedLineName != name) {
        this.$toasted.show("Please save edit first", {
          type: "error",
          duration: "3000",
        });
        return;
      }
      this.editedLine = line;
      this.editedLineName = name;

      this.beforeEditCacheCatch = JSON.parse(JSON.stringify(1));
    },
    createEstimate() {
      let data = JSON.stringify({
        afenum: this.afes.afenum,
        projectid: this.$route.params.id,
        serviceid: this.serviceid,
        qty1: this.uom1Quantity,
        qty2: this.uom2Quantity,
        qty3: this.uom3Quantity,
        pwcaid: this.afes.pwcaid,
      });

      this.$axios.post("service_estimate/create/", data).then((response) => {
        this.getServices(true);

        this.$refs.addEstimate.hide();
      });
    },
    getUoms(row) {
      if (row.groupedUom[0]) {
        this.uom1 = row.groupedUom[0].name;
      }
      if (row.groupedUom[1]) {
        this.uom2 = row.groupedUom[1].name;
      }
      if (row.groupedUom[2]) {
        this.uom3 = row.groupedUom[2].name;
      }

      this.serviceid = row.services[0].serviceid;
    },
    getNoUoms(row) {
      if (row.uom1) {
        this.uom1 = row.uom1;
      }
      if (row.uom2) {
        this.uom2 = row.uom2;
      }
      if (row.uom3) {
        this.uom3 = row.uom3;
      }
      this.serviceid = row.serviceid;
      this.vendorid = row.vid;
    },
    getServices(reset) {
      var url =
        "/service_estimate/get/" +
        this.$route.params.id +
        "/" +
        encodeURIComponent(this.afes.pwcaid);

      this.$axios.get(url).then((response) => {
        this.estimateServicesData = response.data.result;
        this.vendorEstimateData = this.lodash.uniqBy(
          response.data.result,
          "vid"
        );

        //if(reset){
        this.getVendorsForEstimates();
        //}
      });
    },
    async getAllServicesInProject() {
      return this.$axios
        .get("/wts/get/services/" + this.$route.params.id)
        .then((response) => {
          return response.data.result;
        });
    },
    async getNoTotalServices(compare) {
      const that = this;
      const getAssignedServices = await this.getAssignedServicesListForEstimates();
      const getAllServices = await this.getAllServicesInProject();
      const servicesData = await this.loadServicesDataForEstimates();
      const wtsVendorListAll = await this.getWtsVendorListAll();
      const wtsVendorList = this.lodash.uniqBy(wtsVendorListAll, "vid");
      var tempServices = servicesData.filter(
        (x) => x.vid == this.vendorEstimates.vid
      );
      var serviceRemap = this.lodash.differenceBy(
        tempServices,
        compare,
        "serviceid"
      );

      const chain = that.lodash.map(serviceRemap, function (item) {
        var id = getAllServices.findIndex(function (item2, i) {
          return item2.serviceid === item.serviceid;
        });

        var eId = that.estimateServicesData.findIndex(function (item2, i) {
          return item2.serviceid === item.serviceid;
        });

        if (eId >= 0) {
          item.estimate = that.estimateServicesData[eId].estimate;
        } else {
          item.estimate = null;
        }

        item.name = getAllServices[id].name;
        item.uom1 = getAllServices[id].uom1;
        item.uom2 = getAllServices[id].uom2;
        item.uom3 = getAllServices[id].uom3;
        item.pu1 = getAllServices[id].pu1;
        item.pu2 = getAllServices[id].pu2;
        item.pu3 = getAllServices[id].pu3;
        return item;
      });
      this.estimateNoTotalServicesData = chain;
    },
    clearImport() {
      this.successServices = [];
      this.serviceErrors = [];
      this.doc = [];

      const input = this.$refs.csvFile;
      input.type = "text";
      input.type = "file";
      this.$refs.csvFile.value = null;
    },
    clearImport1() {
      this.successServices = [];
      this.serviceErrors = [];
      this.doc = [];

      const input = this.$refs.csvFile;
      input.type = "text";
      input.type = "file";
      this.$refs.csvFile.value = null;
    },
    openModal() {
      this.openFlag = true;
    },
    upload(e) {
      const that = this;
      const fileToLoad = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (fileLoadedEvent) => {
        Papa.parse(fileLoadedEvent.target.result, {
          header: true,
          complete(results) {
            that.doc = results.data;
          },
          error(errors) {},
        });
      };
      reader.readAsText(fileToLoad);
    },
    importEstimates(services) {
      // This part is refactored using the new service estimate list
      const that = this;
      var reMaped = [];

      for (var i = 0; i < services.length; i++) {
        for (
          var j = 0;
          j < this.vendorListServiceDetailsServiceEstimate.length;
          j++
        ) {
          if (
            services[i]["Name"] ==
            this.vendorListServiceDetailsServiceEstimate[j]["servicename"]
          ) {
            var uom1 = 0;
            var uom2 = 0;
            var uom3 = 0;

            if (services[i]["Estimate Quantity 1"] != undefined) {
              uom1 = services[i]["Estimate Quantity 1"];
            }
            if (services[i]["Estimate Quantity 2"] != undefined) {
              uom2 = services[i]["Estimate Quantity 2"];
            }
            if (services[i]["Estimate Quantity 3"] != undefined) {
              uom3 = services[i]["Estimate Quantity 3"];
            }

            reMaped.push({
              qty1: uom1,
              qty2: uom2,
              qty3: uom3,
              serviceid: this.vendorListServiceDetailsServiceEstimate[j][
                "serviceid"
              ],
              projectid: this.$route.params.id,
              afenum: that.afes.afenum,
              vid: this.vendorListServiceDetailsServiceEstimate[j]["vid"],
              companyid: this.vendorListServiceDetailsServiceEstimate[j][
                "companyid"
              ],
              pwcaid: that.afes.pwcaid,
            });
          }
        }
      }

      this.$axios
        .post("/service_estimate/create/bulk", reMaped)
        .then((response) => {
          this.$refs.importEstimates.hide();
          this.$toasted.show("Successfully Imported", {
            type: "success",
            duration: "3000",
          });

          const input = this.$refs.csvFile;
          input.type = "text";
          input.type = "file";
          this.doc = [];
          this.$refs.csvFile.value = null;
        })
        .catch((error) => {
          this.$toasted.show("Import Failed", {
            type: "error",
            duration: "3000",
          });
        });
    },

    editCostCodeOnClick(c1, c2, c3, od) {
      this.costcode1 = c1;
      this.costcode2 = c2;
      this.costcode3 = c3;
      this.optionaldescription = od;
      (this.edit_costcode1 = c1),
        (this.edit_costcode2 = c2),
        (this.edit_costcode3 = c3),
        (this.edit_optionaldescription = od);
    },

    getCurrentPage(p) {
      // alert(p);
      if (p == 1 || p == null) {
        this.offset = 0;
        this.limit = 20;
      } else {
        this.offset = (p - 1) * 20;
        this.limit = 20;
      }
      this.loadData();
    },

    searchFun(searchQuery) {
      this.search_val = searchQuery ? searchQuery : 0;
      this.loadData();
    },

    importFile(doc) {
      let companyid = this.profile.companyid;

      this.$axios
        .post(
          "/wts/import/cost_code_chain/importFile/" + this.profile.companyid,
          doc
        )
        .then((response) => {
          this.$refs.importEstimates.hide();
          this.$toasted.show("Successfully Imported", {
            type: "success",
            duration: "3000",
          });
          this.loadData();
          const input = this.$refs.csvFile;

          if (input !== undefined) {
            input.type = "text";
            input.type = "file";
            this.$refs.csvFile.value = null;
          }
          this.doc = [];
          this.$refs.costCodeTable.refresh();
        })
        .catch((error) => {
          console.log(error, "error");
          this.$toasted.show("Import Failed", {
            type: "error",
            duration: "3000",
          });
        });
    },

    setCostCodeType(type) {
      this.$refs.addNewCC.show();
      this.costCodeType = type;
    },
    addNewCostCode() {
      if (this.costCodeType == 1) {
        let data = JSON.stringify({
          pwcaid: this.pwcaid,
          projectid: this.$route.params.id,
          code: this.addNewCostName,
        });
        this.$http.post("/wts/create/cc1", data).then((response) => {
          this.cc1Data.push({
            cconeid: response.data.id,
            code: this.addNewCostName,
          });
          this.$refs.autocomplete1.setSelected({
            cconeid: response.data.id,
            code: this.addNewCostName,
          });
          this.cc2B = "";
          this.cc3B = "";
          this.$refs.addNewCC.hide();
        });
      }
      if (this.costCodeType == 2) {
        let data = JSON.stringify({
          cconeid: this.cc1BSelected.cconeid,
          projectid: this.$route.params.id,
          code: this.addNewCostName,
        });
        this.$http.post("/wts/create/cc2", data).then((response) => {
          this.cc2Data.push({
            cctwoid: response.data.id,
            code: this.addNewCostName,
          });
          this.$refs.autocomplete2.setSelected({
            cctwoid: response.data.id,
            code: this.addNewCostName,
          });
          this.cc3B = "";
          this.$refs.addNewCC.hide();
        });
      }
      if (this.costCodeType == 3) {
        let data = JSON.stringify({
          cctwoid: this.cc2BSelected.cctwoid,
          projectid: this.$route.params.id,
          code: this.addNewCostName,
        });
        this.$http.post("/wts/create/cc3", data).then((response) => {
          this.cc3Data.push({
            ccthreeid: response.data.id,
            code: this.addNewCostName,
          });
          this.$refs.autocomplete3.setSelected({
            ccthreeid: response.data.id,
            code: this.addNewCostName,
          });
          this.$refs.addNewCC.hide();
        });
      }
    },
    getCC2B() {
      this.cc2B = "";
      this.cc3B = "";
      this.$http
        .get(
          "/wts/get/cc2/" +
            this.$route.params.id +
            "/afe/ccone/" +
            this.cc1BSelected.cconeid
        )
        .then((response) => {
          this.cc2Data = response.data.result;
        })
        .catch((error) => {
          this.$toasted.show("Error. Please contact support.", {
            type: "error",
            duration: "3000",
          });
        });
    },
    getCC3B() {
      this.cc3B = "";
      this.$http
        .get(
          "/wts/get/cc3/" +
            this.$route.params.id +
            "/afe/" +
            this.pwcaid +
            "/cctwo/" +
            this.cc2BSelected.cctwoid
        )
        .then((response) => {
          this.cc3Data = response.data.result.filter(
            (x) => !x.code.includes("---")
          );
        })
        .catch((error) => {
          this.$toasted.show("Error. Please contact support.", {
            type: "error",
            duration: "3000",
          });
        });
    },
    showAddFruit() {
      this.$dialog.prompt({
        message: `Fruit`,
        inputAttrs: {
          placeholder: "e.g. Watermelon",
          maxlength: 20,
          value: this.name,
        },
        confirmText: "Add",
        onConfirm: (value) => {
          this.cc1Data.push(value);
          this.$refs.autocomplete.setSelected(value);
        },
      });
    },
    addToCostCenters(pwcaid) {
      //var findCostCenter = this.costCenters.filter(x=>x.cost_centerid == ccid)

      for (var i = 0; i < this.costCenters.length; i++) {
        if (
          this.costCenters[i].percentage < 0 ||
          this.costCenters[i].percentage > 100
        ) {
          this.$toasted.show("Percentage Error", {
            type: "error",
            duration: "3000",
          });
          return;
        }

        var find = this.costCenters[i].afe_list.findIndex(
          (x) => x.afenum == this.oldAfenum
        );

        if (find != -1) {
          let data = JSON.stringify({
            status: 1,
            cost_centerid: this.costCenters[i].cost_center.cost_centerid,
            afenum: this.oldAfenum,
            new_afenum: this.editafenum,
            percent: this.costCenters[i].percentage,
            pwcaid: pwcaid,
            projectid: this.$route.params.id,
          });

          this.$http
            .post("/cost_center_afe_list/edit/", data)
            .then((response) => {})
            .catch((error) => {});
        } else {
          let data = JSON.stringify({
            status: 1,
            cost_centerid: this.costCenters[i].cost_center.cost_centerid,
            afenum: this.editafenum,
            percent: this.costCenters[i].percentage,
            pwcaid: pwcaid,
            projectid: this.$route.params.id,
          });
          this.costCenters[i].percentage > 0
            ? this.$http
                .post("/cost_center_afe_list/create/", data)
                .then((response) => {})
                .catch((error) => {})
            : null;
        }
      }
    },
    loadCostCenters() {
      this.costCenters = [];

      this.$http
        .get(
          "/cost_center/get/projects/" +
            this.profile.companyid +
            "/" +
            this.$route.params.id
        )
        .then((response) => {
          var temp = response.data.result.filter(
            (x) => x.cost_center.status == 1
          );
          for (var i = 0; i < temp.length; i++) {
            var find = temp[i].afe_list.filter(
              (x) => x.afenum == this.editafenum
            );
            if (find.length > 0) {
              temp[i].percentage = find[0].percent;
            } else {
              temp[i].percentage = 0;
            }
          }
          this.costCenters = temp;
        })
        .catch((error) => {});
    },
    get_service_ccid_total(id) {
      return this.$http.get("/cc_bucket/get/cost/" + id).then((response) => {
        return response.data.result.total;
      });
    },
    addBudget(row) {
      this.costCodes = row;
      this.$refs.addBudgetModal.show();
    },
    addBudgetToCostCode() {
      let data = JSON.stringify({
        cc1_id: this.costCodes.code[0].cconeid,
        cc2_id: this.costCodes.code[0].cctwoid,
        cc3_id: this.costCodes.code[0].ccthreeid,
        budget: this.budget,
        company_id: this.costCodes.code[0].companyid,
        project_id: this.costCodes.code[0].projectid,
        name: "Budget",
        afenum: this.afenum,
        status: 1,
      });
      this.$http
        .post("/cc_budget/create/", data)
        .then((response) => {
          this.$toasted.show("Budget Added Successfully", {
            type: "success",
            duration: "3000",
          });

          for (var i = 0; i < this.chainData.length; i++) {
            if (
              this.chainData[i].cconeid == this.costCodes.code[0].cconeid &&
              this.chainData[i].cctwoid == this.costCodes.code[0].cctwoid &&
              this.chainData[i].ccthreeid == this.costCodes.code[0].ccthreeid
            ) {
              this.chainData[i].budget.push({
                budget: this.budget,
                cc_budgetid: response.data.result.cc_budgetid,
                cc1_id: this.costCodes.code[0].cconeid,
                cc2_id: this.costCodes.code[0].cctwoid,
                cc3_id: this.costCodes.code[0].ccthreeid,
                company_id: this.costCodes.code[0].cconeid,
                name: "Budget",
                project_id: this.costCodes.code[0].projectid,
                status: 1,
              });
            }
          }

          this.$refs.addBudgetModal.hide();
        })
        .catch((error) => {});
    },
    editBudget(row) {
      this.costCodes = row;
      this.budget = this.costCodes.code[0].budget[0].budget;
      this.$refs.editBudgetModal.show();
    },
    editBudgetToCostCode() {
      let data = JSON.stringify({
        cc_budgetid: this.costCodes.code[0].budget[0].cc_budgetid,
        cc1_id: this.costCodes.code[0].cconeid,
        cc2_id: this.costCodes.code[0].cctwoid,
        cc3_id: this.costCodes.code[0].ccthreeid,
        budget: this.budget,
        company_id: this.costCodes.code[0].companyid,
        project_id: this.costCodes.code[0].projectid,
        name: "Budget",
        status: 1,
      });
      this.$http
        .post("/cc_budget/update/", data)
        .then((response) => {
          this.$toasted.show("Budget Edited Successfully", {
            type: "success",
            duration: "3000",
          });

          for (var i = 0; i < this.chainData.length; i++) {
            if (
              this.chainData[i].cconeid == this.costCodes.code[0].cconeid &&
              this.chainData[i].cctwoid == this.costCodes.code[0].cctwoid &&
              this.chainData[i].ccthreeid == this.costCodes.code[0].ccthreeid
            ) {
              this.chainData[i].budget[0].budget = this.budget;
            }
          }

          this.$refs.editBudgetModal.hide();
        })
        .catch((error) => {});
    },
    getTheChain(id) {
      return this.$http
        .get("/wts/get/assign_services/" + id)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {});
    },
    async importCCChainByVendorServices() {
      const getExistingChain = await this.getTheChain(this.$route.params.id);

      var chain = [];
      for (var i = 0; i < this.vendorSelectedServices.length; i++) {
        chain.push({
          serviceid: this.vendorSelectedServices[i].serviceid,
          ccone_code: this.cc1Text,
          cctwo_code: this.cc2Text,
          ccthree_code: this.cc3Text,
        });
      }

      var reducedExisting = getExistingChain.map(function (i) {
        i.serviceid = i.serviceid;
        i.ccone_code = i.ccone_code;
        i.cctwo_code = i.cctwo_code;
        if (i.ccthree_code.includes("---")) {
          i.ccthree_code = "";
        } else {
          i.ccthree_code = i.ccthree_code;
        }
        return i;
      });

      for (var i = 0; i < chain.length; i++) {
        var match = reducedExisting.filter(
          (x) =>
            x.serviceid == chain[i].serviceid &&
            x.ccone_code == chain[i].ccone_code &&
            x.cctwo_code == chain[i].cctwo_code &&
            x.ccthree_code == chain[i].ccthree_code
        );

        if (match.length > 0) {
          chain.splice(i, 1);
        }
      }

      const importChain = await this.importCCChainFromAnotherProject(
        chain,
        this.$route.params.id,
        this.afenum
      );
    },
    async importCCChainByAllVendorServices() {
      var chain = [];
      const getExistingChain = await this.getTheChain(this.$route.params.id);

      var chain = [];
      for (var i = 0; i < this.vendorServices.length; i++) {
        chain.push({
          serviceid: this.vendorServices[i].serviceid,
          ccone_code: this.cc1Text,
          cctwo_code: this.cc2Text,
          ccthree_code: this.cc3Text,
        });
      }

      var reducedExisting = getExistingChain.map(function (i) {
        i.serviceid = i.serviceid;
        i.ccone_code = i.ccone_code;
        i.cctwo_code = i.cctwo_code;
        if (i.ccthree_code.includes("---")) {
          i.ccthree_code = "";
        } else {
          i.ccthree_code = i.ccthree_code;
        }
        return i;
      });

      for (var i = 0; i < chain.length; i++) {
        var match = reducedExisting.filter(
          (x) =>
            x.serviceid == chain[i].serviceid &&
            x.ccone_code == chain[i].ccone_code &&
            x.cctwo_code == chain[i].cctwo_code &&
            x.ccthree_code == chain[i].ccthree_code
        );

        if (match.length > 0) {
          chain.splice(i, 1);
        }
      }

      const importChain = await this.importCCChainFromAnotherProject(
        chain,
        this.$route.params.id,
        this.afenum
      );
    },
    showImportCCVendorModal() {
      this.$axios
        .get("/wts/get/vas/" + this.project_selected)
        .then((response) => {
          var temp = response.data.result.filter(
            (x) => x.vid == this.vendor.vid
          );
          this.vendorServices = temp;
          this.vendorSelectedServices = [];
          this.$refs.importCCVendorModal.show();
        });
    },
    getAnother(id) {
      return this.$http.get("/wts/get/services/" + id).then((response) => {
        var temp = response.data.result;
        return temp;
      });
    },
    getChain(id, services, anotherProjectServices) {
      return this.$http
        .get("/wts/get/assign_services/" + id)
        .then((response) => {
          var temp = response.data.result;

          temp = temp.filter((o) => o.afenum === this.anotherAfe.afenum);

          for (var i = 0; i < temp.length; i++) {
            var find = services.filter(
              (a) => a.serviceid === temp[i].serviceid
            );
            if (find.length > 0) {
              var find2 = anotherProjectServices.filter(
                (a) => a.name === find[0].name && a.code === find[0].code
              );

              if (find2.length > 0) {
                temp[i].serviceid = find2[0].serviceid;
              } else {
                temp[i].serviceid = "na";
              }
            } else {
              temp[i].serviceid = "na";
            }
          }
          return temp;
        })
        .catch((error) => {});
    },
    getSameChain(id, services, anotherProjectServices) {
      return this.$http
        .get("/wts/get/assign_services/" + id)
        .then((response) => {
          var temp = response.data.result;

          temp = temp.filter((o) => o.afenum === this.afenum);

          for (var i = 0; i < temp.length; i++) {
            var find = services.filter(
              (a) => a.serviceid === temp[i].serviceid
            );
            if (find.length > 0) {
              var find2 = anotherProjectServices.filter(
                (a) => a.name === find[0].name && a.code === find[0].code
              );

              if (find2.length > 0) {
                temp[i].serviceid = find2[0].serviceid;
              } else {
                temp[i].serviceid = "na";
              }
            } else {
              temp[i].serviceid = "na";
            }
          }
          return temp;
        })
        .catch((error) => {});
    },
    async exportCCChainToAnotherProject(id, anotherAfe) {
      if (this.anotherProjectid.length == 0 || this.anotherAfe.length == 0) {
        this.$toasted.show("Please select project and number", {
          type: "error",
          duration: "3000",
        });
        return;
      }

      const services = await this.getAnother(this.$route.params.id);
      //selected project
      const anotherProjectServices = await this.getAnother(id.projectid);

      const chain = await this.getSameChain(
        this.$route.params.id,
        services,
        anotherProjectServices
      );
      var tempAfe = this.pwcaid;
      this.pwcaid = this.anotherAfe.pwcaid;

      const importChain = await this.importCCChainFromAnotherProject(
        chain,
        id.projectid,
        this.anotherAfe.afenum
      );
    },
    async importChainFromAnotherProjects(id) {
      if (this.anotherProjectid.length == 0 || this.anotherAfe.length == 0) {
        this.$toasted.show("Please select project and number", {
          type: "error",
          duration: "3000",
        });
        return;
      }
      const services = await this.getAnother(id.projectid);
      const anotherProjectServices = await this.getAnother(
        this.$route.params.id
      );
      const chain = await this.getChain(
        id.projectid,
        services,
        anotherProjectServices
      );
      const importChain = await this.importCCChainFromAnotherProject(
        chain,
        this.$route.params.id,
        this.afenum
      );
    },
    async importJob(chain, projectid, afenum, getProjectChainForImporting) {
      if (this.progressBar == true) {
        this.loadingCount++;

        if (this.importNewVendorServices == true) {
          let send;

          if (this.cc3Text !== "") {
            send = JSON.stringify({
              serviceid: chain.serviceid,
              projectid: projectid,
              ccthreeid: this.cc3.ccthreeid,
              vid: this.vendor.vid,
            });
          } else {
            send = JSON.stringify({
              serviceid: chain.serviceid,
              projectid: projectid,
              cctwoid: this.cc2.cctwoid,
              vid: this.vendor.vid,
            });
          }

          if (
            !chain.ccthree_code.includes("---") &&
            getProjectChainForImporting.filter(
              (x) =>
                x.ccone_code == chain.ccone_code &&
                x.cctwo_code == chain.cctwo_code &&
                x.ccthree_code == chain.ccthree_code &&
                x.afenum == afenum &&
                x.serviceid == chain.serviceid
            ).length > 0
          ) {
            //do not import
          } else if (
            chain.ccthree_code.includes("---") &&
            getProjectChainForImporting.filter(
              (x) =>
                x.ccone_code == chain.ccone_code &&
                x.cctwo_code == chain.cctwo_code &&
                x.afenum == afenum &&
                x.serviceid == chain.serviceid
            ).length > 0
          ) {
            //do not import
          } else {
            return this.$http
              .post("/wts/create/assign_service", send)
              .then((response) => {
                return;
              })
              .catch((error) => {});
          }
        } else {
          var cc1 = "";
          var cc2 = "";
          var cc3 = "";
          var service = "";
          var count = 0;

          if (
            !chain.ccthree_code.includes("---") &&
            getProjectChainForImporting.filter(
              (x) =>
                x.ccone_code == chain.ccone_code &&
                x.cctwo_code == chain.cctwo_code &&
                x.ccthree_code == chain.ccthree_code &&
                x.afenum == afenum &&
                x.serviceid == chain.serviceid
            ).length > 0
          ) {
            //dont not import
          } else if (
            chain.ccthree_code.includes("---") &&
            getProjectChainForImporting.filter(
              (x) =>
                x.ccone_code == chain.ccone_code &&
                x.cctwo_code == chain.cctwo_code &&
                x.afenum == afenum &&
                x.serviceid == chain.serviceid
            ).length > 0
          ) {
          } else {
            const cc1Await = await this.getCC1s(
              chain.ccone_code,
              projectid
            ).then((data) => {
              cc1 = data;
            });

            const cc2Await = await this.getCC2s(
              cc1,
              chain.cctwo_code,
              projectid
            ).then((data) => {
              cc2 = data;
            });

            if (!chain.ccthree_code.includes("---")) {
              const cc3Await = await this.getCC3s(
                cc2,
                chain.ccthree_code,
                projectid
              ).then((data) => {
                cc3 = data;
              });
            }

            let send;
            if (cc3 !== "") {
              send = JSON.stringify({
                serviceid: chain.serviceid,
                projectid: projectid,
                ccthreeid: cc3,
              });
            } else {
              send = JSON.stringify({
                serviceid: chain.serviceid,
                projectid: projectid,
                cctwoid: cc2,
              });
            }

            if (chain.serviceid != "na") {
              count = count + 1;
              return this.$http
                .post("/wts/create/assign_service", send)
                .then((response) => {
                  return;
                })
                .catch((error) => {});
            }
          }
        }
      }
    },
    async importCCChainFromAnotherProject(chain, projectid, afenum) {
      this.loadingImport = true;
      const getProjectChainForImporting = await this.getTheChain(projectid);
      this.loadingCount = 0;
      this.progressBar = true;
      this.progressBarServicesCount = chain.length;
      const that = this;
      var limit = promiseLimit(3);
      Promise.all(
        chain.map((item) => {
          return limit(() =>
            that.importJob(item, projectid, afenum, getProjectChainForImporting)
          );
        })
      )
        .then((results) => {
          that.$toasted.show("Successfully Imported Chains", {
            type: "success",
            duration: "3000",
          });
          that.loadingImport = false;
          // that.enterCC(that.pwcaid, that.afenum);
        })
        .catch((error) => {});
      if (chain.length == 0) {
        this.$toasted.show("Nothing to import", {
          type: "warning",
          duration: "3000",
        });
      }
    },
    loadAfesFromAnother(id) {
      this.anotherAfe = [];

      this.$http.get("/wts/get/afes/" + id).then((response) => {
        this.anotherAfeData = response.data.result;
      });
    },
    sendWssInfo(props) {
      this.status = props.status;
      this.wpid = props.wpid;
      this.wssid = props.wssid;
    },
    changeWssStatus() {
      let data = JSON.stringify({
        id: this.wpid,
        projectid: this.$route.params.id,
        wssid: this.wssid,
        status: this.status,
      });
      this.$http
        .post("/wts/edit/wss_project", data)
        .then((response) => {
          this.$toasted.show("WSS Edited Successfully", {
            type: "success",
            duration: "3000",
          });
          this.$refs.editWSS.hide();
          this.error = false;
          this.loadData();
        })
        .catch((error) => {
          this.$toasted.show("Error editing WSS", {
            type: "error",
            duration: "3000",
          });
          this.error = true;
        });
    },
    getVendorCompanyNameByService(id) {
      const temp = this.wtsVendorListAll.filter((x) => x.serviceid === id);

      if (temp.length > 0) {
        const temp2 = this.wtsVendorList.filter((x) => x.vid === temp[0].vid);
        var company_name = "";
        if (temp2.length > 0) {
          var company_name = temp2[0].company_name;
          return company_name;
        }
      }
    },
    clearForm() {
      this.$validator.reset();
      this.type = "";
      this.name = "";
      this.uom1 = "";
      this.uom2 = "";
      this.uom3 = "";
      this.pu1 = "";
      this.pu2 = "";
      this.pu3 = "";
    },
    clearWellForm() {
      this.error = false;
      this.$validator.reset();
      this.uwi = "";
      this.ln = "";
      this.status = "";
      this.lsd = "";
      this.sec = "";
      this.twp = "";
      this.rge = "";
      this.meridian = "";
      this.lsdBottom = "";
      this.secBottom = "";
      this.twpBottom = "";
      this.rgeBottom = "";
      this.meridianBottom = "";
    },
    startOrEnd(type, pcid, pwid) {
      this.startOrEndNumber = type;
      this.afeDateNumber = pwid;
      this.table = pcid;
    },
    updateAfeDates(value) {
      var pwid = this.afeDateNumber;
      var pcid = this.table;

      var afeData = this.wtsAfeData.filter(function (v, i) {
        return v.pwid === pwid && v.pcid === pcid;
      });

      var startDate = afeData[0].cw_start_date;
      var endDate = afeData[0].cw_end_date;

      if (this.startOrEndNumber === 1) {
        startDate = this.$moment(value).format("YYYY-MM-DD");
        endDate = afeData[0].cw_end_date;
      }
      if (this.startOrEndNumber === 2) {
        startDate = afeData[0].cw_start_date;
        endDate = this.$moment(value).format("YYYY-MM-DD");
      }
      let data = JSON.stringify({
        id: afeData[0].pwcaid,
        projectid: afeData[0].projectid,
        pwid: afeData[0].pwid,
        pcid: afeData[0].pcid,
        afenum: afeData[0].afenum,
        cw_start_date: startDate,
        cw_end_date: endDate,
        status: 0,
      });
      this.$http
        .post("/wts/edit/afe", data)
        .then((response) => {
          this.$toasted.show("Date Edited Successfully.", {
            type: "success",
            duration: "3000",
          });
          this.error = false;
          this.loadData();
        })
        .catch((error) => {
          this.$toasted.show("Error.", { type: "error", duration: "3000" });
          this.error = true;
        });
    },
    getDate(pwid, pcid, type) {
      var afeData = this.wtsAfeData.filter(function (v, i) {
        return v.pwid === pwid && v.pcid === pcid;
      });

      if (afeData.length > 0) {
        if (type === 1) {
          if (afeData[0].cw_start_date !== "3000-01-01") {
            return this.$moment(afeData[0].cw_start_date).format("YYYY/MM/DD");
          }
        }
        if (type === 2) {
          if (afeData[0].cw_end_date !== "3000-01-01") {
            return this.$moment(afeData[0].cw_end_date).format("YYYY/MM/DD");
          }
        }
      }
    },
    clearErrors() {
      this.error = false;
      this.afenum = "";
      this.pcid = "0";
      this.pwid = "0";
      this.$validator.reset();
      this.projectid = {};
    },
    clearEditCCErrors() {
      this.error = false;
      this.$validator.reset();
    },
    openList1() {
      // this.$refs.suggestCC1.listShown = true;
      // this.$refs.suggestCC1.isInFocus = true;
      // this.$refs.suggestCC1.showList();
    },
    suggestCC1(suggest) {
      if (suggest) {
        this.cc1Text = suggest.code;
        this.cconeid = suggest.cconeid;
        this.newCode = 0;
        this.cc2 = [];
        this.cc2Text = "";
        this.cc2Data = [];
        this.cc3Text = "";
        this.cc3Data = [];
        this.enterCC2();
      }
    },
    suggestCC2(suggest) {
      if (suggest) {
        this.cc2Text = suggest.code;
        this.cctwoid = suggest.cctwoid;
        this.cc3 = [];
        this.newCode2 = 0;
        this.cc3Text = "";
        this.cc3Data = [];
        this.enterCC3();
      }
    },
    suggestCC3(suggest) {
      if (suggest) {
        this.ccthreeid = suggest.ccthreeid;
        this.cc3Text = suggest.code;
        this.newCode3 = 0;
      }
    },

    enterCC(pwcaid, afenum) {
      this.cc1Text = "";
      this.pwcaid = pwcaid;
      this.afenum = afenum;
      this.afeCCOne = [];
      this.afeCCTwo = [];
      this.afeCCThree = [];
      this.allCostCodes = [];
      this.totalBudget = 0;
      this.actualTotal = 0;
      this.totalnet = 0;

      this.$http
        .get(
          "/wts/get/afe/ccone/project/" +
            this.$route.params.id +
            "/pwca/" +
            pwcaid
        )
        .then((response) => {
          var obj = JSON.parse(JSON.stringify(response));
          if (response.status == 200) {
            this.afeCCOne = obj.data.CCdata;

            this.afeCCOne.map((x, i) => {
              if (x.company_name.length !== 0) {
                this.actualTotal = this.actualTotal + Number(x.vendorTotal);
                this.totalBudget = this.totalBudget + Number(x.budget);

                this.totalnet = 0;

                this.totalnet = this.totalBudget - this.actualTotal;

                this.allCostCodes.push({
                  id: i,
                  code: x.code,
                  cctwo_code: x.cctwo_code,
                  ccthree_code: x.ccthree_code,
                  company_name: x.company_name,
                  pwcaid: x.pwcaid,
                  companyid: x.companyid,
                  projectid: x.projectid,
                  cconeid: x.cconeid,
                  cctwoid: x.cctwoid,
                  ccthreeid: x.ccthreeid,
                  budget: x.budget,
                  total: x.vendorTotal,
                  vid: x.vid,
                });
              }
            });
          }

          this.ccPanel = 1;
        })
        .catch((error) => {
          this.$toasted.show(
            "Error. Cannot find chain. Contact support." + error,
            { type: "error", duration: "3000" }
          );
        });
    },

    provideVendorCompanyName(
      projectid,
      companyid,
      pwcaid,
      cconeid,
      cctwoid,
      ccthreeid,
      cc1_code,
      cc2_code,
      cc3_code
    ) {
      this.vendorCompanyNameAFE = [];
      this.cc1_code = cc1_code;
      this.cc2_code = cc2_code;
      this.cc3_code = cc3_code;

      this.$http
        .get(
          "/wts/get/afe/vcomp/project/" +
            this.$route.params.id +
            "/comp/" +
            companyid +
            "/pwca/" +
            pwcaid +
            "/ccone/" +
            cconeid +
            "/cctwo/" +
            cctwoid +
            "/ccthree/" +
            ccthreeid
        )
        .then((response) => {
          this.projectidForBudget = projectid;
          this.companyidForBudget = companyid;
          this.pwcaidForBudget = pwcaid;
          this.cconeidForBudget = cconeid;
          this.cctwoidForBudget = cctwoid;
          this.ccthreeidForBudget = ccthreeid;

          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj, "obj vendor comp name");

          if (response != null) {
            this.vendorCompanyNameAFE = obj.data.rows;
            this.VendorServiceEstimateData = obj.data.result;

            this.totalServiceEstimate = 0;
            this.TotalActual = 0;
            this.vendorCompanyNameAFE.map((y, i) => {
              this.TotalActual = this.TotalActual + y.vendorTotal;
              this.totalestimates = 0;
              if (obj.data.result.length > 0) {
                obj.data.result.map((x, l) => {
                  if (y.vid === x.vid) {
                    this.totalestimates =
                      this.totalestimates +
                      (x.pu1 * x.qty1 + x.pu2 * x.qty2 + x.pu3 * x.qty3);
                    this.vendorCompanyNameAFE[
                      i
                    ].totalestimates = this.totalestimates;
                  }
                });
              }
            });

            if (obj.data.result.length > 0) {
              obj.data.result.map((y, l) => {
                this.totalServiceEstimate =
                  this.totalServiceEstimate +
                  (y.pu1 * y.qty1 + y.pu2 * y.qty2 + y.pu3 * y.qty3);
              });
            } else {
              this.totalServiceEstimate = 0;
            }

            this.toggle = false;
          }

          this.ccPanel = 1;
        })
        .catch((error) => {
          this.$toasted.show(
            "Error. Cannot find chain. Contact support." + error,
            { type: "error", duration: "3000" }
          );
        });
    },

    updateBugetForVenor(
      projectId,
      companyID,
      pwcaID,
      cconeID,
      cctwoID,
      ccThreeID,
      budget,
      i
    ) {
      if (true) {
        var updatedBudget = budget;

        var data = {
          projectid: this.$route.params.id,
          companyid: companyID,
          pwcaid: pwcaID,
          cconeid: cconeID,
          cctwoid: cctwoID,
          ccthreeid: ccThreeID,
          updatedbudget: budget,
          afenum: this.afenum,
        };

        // updateBugetForVenor
        this.$http
          .post("/wts/get/afe/update/budget/project", JSON.stringify(data))
          .then((response) => {
            this.totalBudget = 0;
            this.totalnet = 0;

            this.allCostCodes[i].budget = updatedBudget;

            this.allCostCodes.map((x) => {
              this.totalBudget = this.totalBudget + Number(x.budget);
              this.totalnet = this.totalBudget - this.actualTotal;
            });

            this.wtsAfeData.map((x, i) => {
              if (x.pwcaid == pwcaID) {
                this.wtsAfeData[i].TotalBudget = this.totalBudget;
              }
            });
          })
          .catch((error) => {
            this.$toasted.show(
              "Error. Cannot find chain. Contact support." + error,
              { type: "error", duration: "3000" }
            );
          });
      } else {
      }
    },
    provideVendorServices(
      projectId,
      companyID,
      pwcaID,
      cconeID,
      cctwoID,
      ccThreeID,
      vendorID
    ) {
      this.afeDetailsTableDataRaj = [];
      this.All_vendor_Services = [];
      if (this.childRowVendor == vendorID) {
        this.$refs.ccVendorTable.openChildRows = [vendorID];
      } else {
        this.childRowVendor = vendorID;
        this.$refs.ccVendorTable.openChildRows = [vendorID];
      }

      this.$http
        .get(
          "/wts/get/afe/services/project/" +
            this.$route.params.id +
            "/comp/" +
            companyID +
            "/pwca/" +
            pwcaID +
            "/ccone/" +
            cconeID +
            "/cctwo/" +
            cctwoID +
            "/ccthree/" +
            ccThreeID +
            "/vendor/" +
            vendorID
        )
        .then((response) => {
          var obj = JSON.parse(JSON.stringify(response));
          this.afeDetailsTableDataRaj = obj.data.vendorservicelist;
          this.All_vendor_Services = obj.data.vendorservicelist;
          console.log(this.All_vendor_Services, "data=1");

          this.afeDetailsTableDataRaj.map((x, i) => {
            this.VendorServiceEstimateData.map((y, j) => {
              if (x.vid == y.vid && x.serviceid == y.serviceid) {
                this.afeDetailsTableDataRaj[i].pu1 = y.pu1;
                this.afeDetailsTableDataRaj[i].pu2 = y.pu2;
                this.afeDetailsTableDataRaj[i].pu3 = y.pu3;
                this.afeDetailsTableDataRaj[i].qty1 = y.qty1;
                this.afeDetailsTableDataRaj[i].qty2 = y.qty2;
                this.afeDetailsTableDataRaj[i].qty3 = y.qty3;
              }
            });
          });

          this.vendorCompanyServiceSize = obj.data.size;
        })
        .catch((error) => {
          this.$toasted.show(
            "Error. Cannot find chain. Contact support." + error,
            { type: "error", duration: "3000" }
          );
        });
    },
    deleteVendorService(
      motherCompanyID,
      projectID,
      pwcaID,
      cconeID,
      cctwoID,
      ccthreeID,
      vendorID,
      serviceID,
      uom1Total,
      uom2Total,
      uom3Total,
      service_ccid
    ) {
      if (uom1Total == 0 && uom2Total == 0 && uom3Total == 0) {
        if (
          confirm(
            "Are you sure you want to delete this service from the cost code chain?"
          )
        ) {
          this.afeDetailsTableDataRaj = this.afeDetailsTableDataRaj.filter(
            (data) => data.serviceid != serviceID
          );
          this.vendorCompanyServiceSize = this.afeDetailsTableDataRaj.length;

          this.$http
            .get(
              "/wts/get/afe/service/delete/compid/" +
                motherCompanyID +
                "/project/" +
                projectID +
                "/pwca/" +
                pwcaID +
                "/ccone/" +
                cconeID +
                "/cctwo/" +
                cctwoID +
                "/cthree/" +
                ccthreeID +
                "/vendor/" +
                vendorID +
                "/service/" +
                serviceID +
                "/service_ccid/" +
                service_ccid
            )
            .then((response) => {})
            .catch((error) => {
              this.$toasted.show(
                "Error. Cannot delete chain. Contact support." + error,
                { type: "error", duration: "3000" }
              );
            });
        } else {
        }
      } else {
        alert(
          "This item has been used in an invoice already, you can not delete it"
        );
      }
    },
    deleteAllVendorService(
      motherCompanyID,
      projectID,
      pwcaID,
      cconeID,
      cctwoID,
      ccthreeID,
      vendorID
    ) {
      if (true) {
        this.afeDetailsTableDataRaj = this.afeDetailsTableDataRaj.filter(
          (data) => data.uom1total + data.uom2total + data.uom3total != 0
        );
        this.vendorCompanyServiceSize = this.afeDetailsTableDataRaj.length;
        let data = this.lodash.map(this.All_vendor_Services, "service_ccid");

        this.$http
          .put(
            "/wts/get/afe/service/delete/all/compid/" +
              motherCompanyID +
              "/project/" +
              projectID +
              "/pwca/" +
              pwcaID +
              "/ccone/" +
              cconeID +
              "/cctwo/" +
              cctwoID +
              "/cthree/" +
              ccthreeID +
              "/vendor/" +
              vendorID,
            data
          )
          .then((response) => {})
          .catch((error) => {
            this.$toasted.show(
              "Error. Cannot delete chain. Contact support." + error,
              { type: "error", duration: "3000" }
            );
          });
      } else {
        e.preventDefault();
      }
    },
    // This function is created by Raj Bhatta for Service Estimate
    getVendorFromAfeForServiceEstimate(reset) {
      var url =
        "/service_estimate/get/vendor/project/" +
        this.$route.params.id +
        "/afe/" +
        this.afes.pwcaid;

      this.$axios.get(url).then((response) => {
        this.pwcaidForReload = this.afes.pwcaid;
        this.vendorListServiceEstimate = response.data;
      });
    },
    getVendorServiceForServiceEstimate() {
      var vendorID = this.vendorEstimates.vid;
      var projectID = this.$route.params.id;

      var url =
        "/service_estimate/get/vendor/services/project/" +
        projectID +
        "/vid/" +
        vendorID +
        "/afe/" +
        this.afes.pwcaid;

      this.$axios.get(url).then((response) => {
        this.vendorListServiceDetailsServiceEstimate =
          response.data.vendorservice;
        this.vendorEstimatedServiceListServiceEstimate =
          response.data.serviceestimate;
      });
    },
    createEstimateUom1() {
      let data = JSON.stringify({
        afenum: this.afes.afenum,
        projectid: this.$route.params.id,
        serviceid: this.serviceid,
        vendorid: this.vendorid,
        pwcaid: this.afes.pwcaid,
        afenum: this.afes.afenum,
        companyid: this.afes.companyid,
        qty1: this.uom1Quantity,
        qty2: 0,
        qty3: 0,
      });
      this.$axios
        .post("service_estimate/create/new", data)
        .then((response) => {
          this.getServiceEstimateListForUpdate();
        })
        .catch((error) => {
          this.$toasted.show(
            "Error inside API for UOM 1 Update, Contact ADMIN for API verification" +
              error,
            { type: "error", duration: "3000" }
          );
        });

      this.$refs.addEstimateUom1.hide();
    },
    createEstimateUom2() {
      let data = JSON.stringify({
        afenum: this.afes.afenum,
        projectid: this.$route.params.id,
        serviceid: this.serviceid,
        vendorid: this.vendorid,
        pwcaid: this.afes.pwcaid,
        afenum: this.afes.afenum,
        companyid: this.afes.companyid,
        qty1: 0,
        qty2: this.uom2Quantity,
        qty3: 0,
      });

      this.$axios
        .post("service_estimate/create/new", data)
        .then((response) => {
          this.getServiceEstimateListForUpdate();
        })
        .catch((error) => {
          this.$toasted.show(
            "Error inside API for UOM 2 Update, Contact ADMIN for API verification" +
              error,
            { type: "error", duration: "3000" }
          );
        });

      this.$refs.addEstimateUom2.hide();
    },
    createEstimateUom3() {
      let data = JSON.stringify({
        afenum: this.afes.afenum,
        projectid: this.$route.params.id,
        serviceid: this.serviceid,
        vendorid: this.vendorid,
        pwcaid: this.afes.pwcaid,
        afenum: this.afes.afenum,
        companyid: this.afes.companyid,
        qty1: 0,
        qty2: 0,
        qty3,
      });

      this.$axios
        .post("service_estimate/create/new", data)
        .then((response) => {
          this.getServiceEstimateListForUpdate();
        })
        .catch((error) => {
          this.$toasted.show(
            "Error inside API for UOM 3 Update, Contact ADMIN for API verification" +
              error,
            { type: "error", duration: "3000" }
          );
        });

      this.$refs.addEstimateUom3.hide();
    },
    getServiceEstimateListForUpdate() {
      this.$axios
        .get(
          "/service_estimate/get/vendor/services/estimate/project/" +
            this.$route.params.id +
            "/vid/" +
            this.vendorid +
            "/afe/" +
            this.afes.pwcaid
        )
        .then((response) => {
          this.vendorEstimatedServiceListServiceEstimate = response.data;
        })
        .catch((error) => {
          this.$toasted.show(
            "Error Getting Service Estimate from Server" + error,
            { type: "error", duration: "3000" }
          );
        });
    },
    sendFilteredNew() {
      var filteredElement = [];
      var estimatedObj = {};
      this.vendorListServiceDetailsServiceEstimate.forEach(
        (outerElement, index, array) => {
          if (this.vendorEstimatedServiceListServiceEstimate.length > 0) {
            this.vendorEstimatedServiceListServiceEstimate.forEach(
              (innerElement, index, array) => {
                if (outerElement.serviceid == innerElement.serviceid) {
                  estimatedObj = {
                    name: outerElement.servicename,
                    uom1: outerElement.uom1,
                    estimateQuantity1: innerElement.qty1,
                    uom2: outerElement.uom1,
                    estimateQuantity2: innerElement.qty2,
                    uom3: outerElement.uom1,
                    estimateQuantity3: innerElement.qty3,
                  };

                  filteredElement.push(estimatedObj);
                } else {
                  estimatedObj = {
                    name: outerElement.servicename,
                    uom1: outerElement.uom1,
                    estimateQuantity1: 0,
                    uom2: outerElement.uom1,
                    estimateQuantity2: 0,
                    uom3: outerElement.uom1,
                    estimateQuantity3: 0,
                  };

                  filteredElement.push(estimatedObj);
                }
              }
            );
          } else {
            estimatedObj = {
              name: outerElement.servicename,
              uom1: outerElement.uom1,
              estimateQuantity1: 0,
              uom2: outerElement.uom1,
              estimateQuantity2: 0,
              uom3: outerElement.uom1,
              estimateQuantity3: 0,
            };
            filteredElement.push(estimatedObj);
          }
        }
      );

      return filteredElement;
    },
    // The above part was written by Raj Bhatta

    enterCC2() {
      this.cc2Text = "";
      this.loadCostCodeChain();

      this.$http
        .get(
          "/wts/get/cc2/" +
            this.$route.params.id +
            "/afe/ccone/" +
            this.cc1.cconeid
        )
        .then((response) => {
          this.cc2Data = response.data.result;
        })
        .catch((error) => {
          this.$toasted.show("Error getting chaom. Please contact support.", {
            type: "error",
            duration: "3000",
          });
        });
    },
    enterCC3() {
      this.cc3Text = "";
      this.loadCostCodeChain();
      this.$http
        .get(
          "/wts/get/cc3/" +
            this.$route.params.id +
            "/afe/" +
            this.pwcaid +
            "/cctwo/" +
            this.cctwoid
        )
        .then((response) => {
          this.cc3Data = response.data.result.filter(
            (o) => !o.code.includes("---")
          );
        })
        .catch((error) => {
          this.$toasted.show("Error getting chain.", {
            type: "error",
            duration: "3000",
          });
        });
    },
    addLine() {
      if (
        this.cc1Text === null ||
        this.cc1Text === "" ||
        this.cc2Text === null ||
        this.cc2Text === "" ||
        this.service === null ||
        this.service === ""
      ) {
        this.errorChain = true;
        return;
      }
      this.createAssignService();
    },
    addCC1() {
      var match = this.cc1Data.filter((o) => o.code === this.cc1Text);
      if (match.length > 0) {
        this.$toasted.show(
          "Please select from dropdown properly if options are provided",
          { type: "error", duration: "5000" }
        );
        return;
      }

      let data = JSON.stringify({
        pwcaid: this.pwcaid,
        projectid: this.$route.params.id,
        code: this.cc1Text,
      });
      this.$http
        .post("/wts/create/cc1", data)
        .then((response) => {
          this.$toasted.show("Added Successfully", {
            type: "success",
            duration: "3000",
          });
          this.cc1Data.push({
            code: this.cc1Text,
            status: 0,
            cconeid: response.data.id,
          });

          this.cc1 = {
            code: this.cc1Text,
            status: 0,
            cconeid: response.data.id,
          };

          this.cconeid = response.data.id;
          this.cc2Data = [];
          this.cc2Text = [];
          this.newCode = 0;
          this.error = false;
        })
        .catch((error) => {
          this.$toasted.show("Error creating code", {
            type: "error",
            duration: "3000",
          });
          this.error = true;
        });
    },
    addCC2() {
      var match = this.cc2Data.filter((o) => o.code === this.cc2Text);
      if (match.length > 0) {
        this.$toasted.show(
          "Please select from dropdown properly if options are provided",
          { type: "error", duration: "5000" }
        );
        return;
      }
      let data = JSON.stringify({
        cconeid: this.cc1.cconeid,
        projectid: this.$route.params.id,
        code: this.cc2Text,
      });
      this.$http
        .post("/wts/create/cc2", data)
        .then((response) => {
          this.$toasted.show("Added Successfully", {
            type: "success",
            duration: "3000",
          });

          this.cc2Data.push({
            code: this.cc2Text,
            status: 0,
            cctwoid: response.data.id,
          });
          this.cc2 = this.cc2Data[this.cc2Data.length - 1];
          this.cctwoid = response.data.id;
          this.newCode2 = 0;
          this.cc3Data = [];
          this.cc3Text = "";

          this.$refs.suggestCC3.list = [];
          this.error = false;
        })
        .catch((error) => {});
    },
    addCC3() {
      var match = this.cc3Data.filter((o) => o.code === this.cc3Text);
      if (match.length > 0) {
        this.$toasted.show(
          "Please select from dropdown properly if options are provided",
          { type: "error", duration: "5000" }
        );
        return;
      }
      let data = JSON.stringify({
        cctwoid: this.cc2.cctwoid,
        projectid: this.$route.params.id,
        code: this.cc3Text,
      });
      this.$http
        .post("/wts/create/cc3", data)
        .then((response) => {
          this.$toasted.show("Added Successfully", {
            type: "success",
            duration: "3000",
          });

          this.cc3Data.push({
            code: this.cc3Text,
            status: 0,
            ccthreeid: response.data.id,
          });
          this.newCode3 = 0;
          this.cc3 = this.cc3Data[this.cc3Data.length - 1];
          this.ccthreeid = response.data.id;
          this.error = false;
        })
        .catch((error) => {
          this.$toasted.show("Error creating", {
            type: "error",
            duration: "3000",
          });
        });
    },
    createAssignService() {
      let data;
      if (this.cc3Text.length > 0) {
        data = JSON.stringify({
          serviceid: this.service.serviceid,
          projectid: this.$route.params.id,
          ccthreeid: this.ccthreeid,
        });
      } else {
        data = JSON.stringify({
          serviceid: this.service.serviceid,
          projectid: this.$route.params.id,
          cctwoid: this.cc2.cctwoid,
        });
      }
      this.$http
        .post("/wts/create/assign_service", data)
        .then((response) => {
          this.$toasted.show("Service added successfully", {
            type: "success",
            duration: "3000",
          });
          this.error = false;
          this.cc2 = [];
          this.cc3 = [];
          this.cc2Text = "";
          this.cc3Text = "";
          this.service = [];
          this.loadCostCodeChain();
        })
        .catch((error) => {
          this.$toasted.show("Error adding service.", {
            type: "error",
            duration: "3000",
          });
        });
    },
    getServiceName(sid) {
      var id = this.servicesData.findIndex(function (item, i) {
        return item.serviceid === sid;
      });
      return this.servicesData[id].name;
    },
    sendInfo(id) {
      this.error = false;
      var val = id;
      let data = this.wtsWellData;
      var id = data.findIndex(function (item, i) {
        return item.pwid === val;
      });
    },
    sendEditInfo(
      id,
      pwid,
      pcid,
      afenum,
      cw_start_date,
      cw_end_date,
      status,
      is_default
    ) {
      this.id = id;
      this.pwid = pwid;
      this.pcid = pcid;
      this.editafenum = afenum;
      this.oldAfenum = afenum;
      this.cw_start_date = cw_start_date;
      this.cw_end_date = cw_end_date;
      this.status = status;
      this.is_default_afe = is_default;
    },
    // WELLS
    wellValidator(scope) {
      var retVal = confirm(
        "Are you sure the information is correct? Original UWI or name cannot be edited in the future."
      );
      if (retVal == true) {
        this.$validator.validateAll(scope).then((result) => {
          if (result) {
            var lsdsurface = "";
            var lsdbottom = "";

            if (!this.wellChange) {
              lsdsurface =
                "1" +
                this.locEx +
                "/" +
                this.lsd +
                "-" +
                this.sec +
                "-" +
                this.twp +
                "-" +
                this.rge +
                "W" +
                this.meridian +
                "/0" +
                this.seq;
              if (this.lsdBottom != "") {
                lsdbottom =
                  this.lsdBottom +
                  "-" +
                  this.secBottom +
                  "-" +
                  this.twpBottom +
                  "-" +
                  this.rgeBottom +
                  "W" +
                  this.meridianBottom;
              }
            } else {
              lsdsurface =
                "20" +
                this.locEx +
                "/" +
                this.qtr +
                "-" +
                this.unit +
                "-" +
                this.blk +
                " " +
                this.map +
                "-" +
                this.msd +
                "-" +
                this.sht +
                "/0" +
                this.seq;
              if (this.qtrBottom != "") {
                lsdbottom =
                  this.qtrBottom +
                  "-" +
                  this.unitBottom +
                  "-" +
                  this.blkBottom +
                  " " +
                  this.mapBottom +
                  "-" +
                  this.msdBottom +
                  "-" +
                  this.shtBottom;
              }
            }

            let data = JSON.stringify({
              projectid: this.$route.params.id,
              uwi: lsdsurface,
              uwi_2: "",
              uwi_3: "",
              ln: this.ln,
              lsdsurface: lsdbottom,
              lsdbottom: lsdsurface,
            });
            this.$http
              .post("/wts/create/well", data)
              .then((response) => {
                this.$toasted.show("Created successfully.", {
                  type: "success",
                  duration: "3000",
                });
                this.$refs.addWell.hide();
                this.loadData();
                this.error = false;
              })
              .catch((error) => {
                this.$toasted.show("Error.", {
                  type: "error",
                  duration: "3000",
                });
                this.error = true;
              });
            return;
          }
        });
      }
    },
    sendWellId(pwid) {
      this.$http
        .get("/wts/get/wells/" + this.$route.params.id)
        .then((response) => {
          let data = response.data.result;
          var index = data.findIndex(function (item, i) {
            return item.pwid === pwid;
          });

          this.id = data[index].pwid;
          this.projectid = data[index].projectid;
          this.uwi = data[index].uwi;
          this.uwi2 = data[index].uwi_2;
          this.uwi3 = data[index].uwi_3;
          this.ln = data[index].ln;
          this.status = data[index].status;

          this.lsdbottom = data[index].lsdbottom;
          this.lsdsurface = data[index].lsdsurface;

          this.surfaceLocation = data[index].lsdsurface;

          this.lsd = data[index].lsdsurface.split("-")[0];
          this.sec = data[index].lsdsurface.split("-")[1];
          this.twp = data[index].lsdsurface.split("-")[2];
          this.rge = data[index].lsdsurface.split("-")[3];
          this.meridian = data[index].lsdsurface.split("-")[4];
          this.lsdBottom = data[index].lsdbottom.split("-")[0];
          this.secBottom = data[index].lsdbottom.split("-")[1];
          this.twpBottom = data[index].lsdbottom.split("-")[2];
          this.rgeBottom = data[index].lsdbottom.split("-")[3];
          this.meridianBottom = data[index].lsdbottom.split("-")[4];
        })
        .catch((error) => {
          this.$toasted.show("Error.", { type: "error", duration: "3000" });
        });
    },
    sendServiceVendor(id) {
      this.error = false;
      this.serviceid = id;
      this.$http
        .get("/wts/get/vendors/" + this.project_selected)
        .then((response) => {
          let data = response.data.result;
          this.wtsVendorList = data;
        })
        .catch((error) => {});
    },
    sendCatInfo(pcid, projectid, status, description, category) {
      this.error = false;
      this.pcid = pcid;
      this.projectid = projectid;
      this.status = status;
      this.description = description;
      this.category = category;
    },
    deleteAssignService(id) {
      let data = JSON.stringify({
        id: id,
        projectid: this.$route.params.id,
      });
      this.$http
        .post("/wts/delete/assign_service", data)
        .then((response) => {
          this.$toasted.show("Assignment deleted.", {
            type: "success",
            duration: "3000",
          });
          //this.loadCostCodeChain();
          var find = this.chainData.findIndex((x) => x.service_ccid == id);
          Vue.delete(this.chainData, find);
        })
        .catch((error) => {
          this.$toasted.show("Error. Cost codes in use", {
            type: "error",
            duration: "3000",
          });
        });
    },
    //AFES
    addAfeToCostCenters(afe_id, afenum, percentage) {
      for (var i = 0; i < this.costCenters.length; i++) {
        let data = JSON.stringify({
          cost_centerid: this.costCenters[i].cost_center.cost_centerid,
          afenum: afenum,
          percent: this.costCenters[i].percentage,
          status: 1,
          pwcaid: afe_id,
          projectid: this.$route.params.id,
        });
        this.costCenters[i].percentage > 0
          ? this.$http
              .post("/cost_center_afe_list/create/", data)
              .then((response) => {})
              .catch((error) => {})
          : null;
      }
    },
    addAfeValidator(scope) {
      var percentTotal = this.costCenters.reduce(function (total, item) {
        return total + parseFloat(item.percentage);
      }, 0);

      if (percentTotal != 100) {
        this.$toasted.show("Cost Centers Must Equal 100%.", {
          type: "error",
          duration: "3000",
        });
        return;
      }

      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let data = JSON.stringify({
            pwid: this.pwid,
            pcid: this.pcid,
            afenum: this.addnewafenum,
            projectid: this.$route.params.id,
            cw_start_date: "3000-01-01",
            cw_end_date: "3000-01-01",
          });
          this.$http
            .post("/wts/create/afe", data)
            .then((response) => {
              this.addAfeToCostCenters(response.data.id, this.addnewafenum);

              this.$toasted.show("Created successfully.", {
                type: "success",
                duration: "3000",
              });
              this.error = false;
              this.$refs.addAfeNum.hide();
              this.loadData();
            })
            .catch((error) => {
              this.$toasted.show("Error.", { type: "error", duration: "3000" });
              this.error = true;
            });
          return;
        }
      });
    },
    editAfeValidate(scope) {
      var percentTotal = this.costCenters.reduce(function (total, item) {
        return total + parseFloat(item.percentage);
      }, 0);

      if (percentTotal != 100) {
        this.$toasted.show("Cost Centers Must Equal 100%.", {
          type: "error",
          duration: "3000",
        });
        return;
      }
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.id,
            projectid: this.$route.params.id,
            pwid: this.pwid,
            pcid: this.pcid,
            afenum: this.editafenum,
            cw_start_date: this.cw_start_date,
            cw_end_date: this.cw_end_date,
            status: this.status,
          });

          console.log(data, "transfered data");
          this.$http
            .post("/wts/edit/afe", data)
            .then((response) => {
              this.$toasted.show("Edited Successfully.", {
                type: "success",
                duration: "3000",
              });
              this.error = false;
              this.$refs.editAfeNum.hide();
              this.addToCostCenters(this.id);
              this.loadData();
            })
            .catch((error) => {
              this.$toasted.show("Error.", { type: "error", duration: "3000" });
              this.error = true;
            });
          return;
        }
      });
    },
    editCC(num, cc, pwcaid) {
      this.num = num;
      if (num === 1) {
        this.pwcaid = pwcaid;
        this.status = cc.status;
        this.code = cc.code;
        this.id = cc.cconeid;
      }
      if (num === 2) {
        this.status = cc.status;
        this.code = cc.code;
        this.id = cc.cctwoid;
        this.cconeid = this.cc1.cconeid;
      }
      if (num === 3) {
        this.status = cc.status;
        this.code = cc.code;
        this.id = cc.ccthreeid;
        this.cctwoid = this.cc2.cctwoid;
      }
    },
    editCCValidater(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let data = [];
          var url = "";

          if (this.num === 1) {
            data = JSON.stringify({
              id: this.id,
              pwcaid: this.pwcaid,
              projectid: this.$route.params.id,
              code: this.code,
              status: this.status,
            });
            url = "/wts/edit/cc1";
          }
          if (this.num === 2) {
            data = JSON.stringify({
              id: this.id,
              cconeid: this.cc1.cconeid,
              projectid: this.$route.params.id,
              code: this.code,
              status: this.status,
            });
            url = "/wts/edit/cc2";
          }
          if (this.num === 3) {
            data = JSON.stringify({
              id: this.id,
              cctwoid: this.cc2.cctwoid,
              projectid: this.$route.params.id,
              code: this.code,
              status: this.status,
            });
            url = "/wts/edit/cc3";
          }

          this.$http
            .post(url, data)
            .then((response) => {
              this.$toasted.show("Edited Successfully.", {
                type: "success",
                duration: "3000",
              });
              this.error = false;
              this.$refs.editCC.hide();
              if (this.num === 1) {
                this.cc1Text = this.code;

                this.$refs.suggestCC1.list[0].code = this.code;
                //this.$refs.suggestCC1.selected.code = this.code;
                // this.enterCC(this.pwcaid, this.afenum);
              }
              if (this.num === 2) {
                this.cc2Text = this.code;
                //this.$refs.suggestCC2.selected.code = this.code;
                //this.$refs.suggestCC2.list[0].code = this.code;
                this.enterCC2();
              }
              if (this.num === 3) {
                //this.$refs.suggestCC3.selected.code = this.code;
                this.$refs.suggestCC3.list[0].code = this.code;
                this.cc3Text = this.code;
                this.enterCC3();
              }
            })
            .catch((error) => {
              this.$toasted.show("Error. Unsuccessful Edited", {
                type: "error",
                duration: "3000",
              });
            });
          return;
        }
      });
    },
    clearCat() {
      this.category = "";
      this.description = "";
    },
    addCat(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let data = JSON.stringify({
            projectid: this.$route.params.id,
            category: this.category,
            description: this.description,
          });
          this.$http
            .post("/wts/create/cat", data)
            .then((response) => {
              this.$toasted.show("Category created successfully.", {
                type: "success",
                duration: "3000",
              });
              this.loadData();
              this.$refs.addCat.hide();
            })
            .catch((error) => {
              this.$toasted.show("Error.", { type: "error", duration: "3000" });
              this.error = true;
            });
          return;
        }
      });
    },

    addCostCodeFun(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let data = JSON.stringify({
            companyid: this.profile.companyid,
            costcode1: this.costcode1,
            costcode2: this.costcode2,
            costcode3: this.costcode3,
            optionaldescription: this.optionaldescription,
          });
          this.$http
            .post("/wts/add/cost_code_chain/addCostCode/1", data)
            .then((response) => {
              this.$toasted.show("CostCode added successfully.", {
                type: "success",
                duration: "3000",
              });
              this.costcode1 = "";
              this.costcode2 = "";
              this.costcode3 = "";
              this.optionaldescription = "";
              this.$refs.addCostCode.hide();
              this.$refs.costCodeTable.refresh();
            })
            .catch((error) => {
              this.$toasted.show("Error.", { type: "error", duration: "3000" });
              this.error = true;
            });
          return;
        }
      });
    },

    clearModal(scope) {
      this.costcode1 = "";
      this.costcode2 = "";
      this.costcode3 = "";
      this.optionaldescription = "";
    },

    deleteCostCodeFun(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let data = JSON.stringify({
            companyid: this.profile.companyid,
            costcode1: this.costcode1,
            costcode2: this.costcode2,
            costcode3: this.costcode3,
            optionaldescription: this.optionaldescription,
          });
          this.$http
            .post("/wts/add/cost_code_chain/addCostCode/-1", data)
            .then((response) => {
              this.$toasted.show("CostCode deleted successfully.", {
                type: "success",
                duration: "3000",
              });
              this.costcode1 = "";
              this.costcode2 = "";
              this.costcode3 = "";
              this.optionaldescription = "";
              this.$refs.deleteCostCode.hide();
              this.$refs.costCodeTable.refresh();
            })
            .catch((error) => {
              this.$toasted.show("Error.", { type: "error", duration: "3000" });
              this.error = true;
            });
          return;
        }
      });
    },

    // Delete All Cost Code;
    deleteAllCostCodeOnClick(scope) {
      let data = JSON.stringify({
        companyid: this.profile.companyid,
      });

      this.$http
        .post("/wts/add/cost_code_chain/addCostCode/-2", data)
        .then((response) => {
          this.$toasted.show(" All CostCode deleted successfully.", {
            type: "success",
            duration: "3000",
          });
          this.costcode1 = "";
          this.costcode2 = "";
          this.costcode3 = "";
          this.optionaldescription = "";
          this.$refs.deleteCostCode.hide();
          this.$refs.costCodeTable.refresh();
        })
        .catch((error) => {
          this.$toasted.show("Error.", { type: "error", duration: "3000" });
          this.error = true;
        });
      return;
    },

    editCostCodeFun(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let data = JSON.stringify({
            companyid: this.profile.companyid,
            costcode1: this.costcode1,
            costcode2: this.costcode2,
            costcode3: this.costcode3,
            optionaldescription: this.optionaldescription,
            old_costcode1: this.edit_costcode1,
            old_costcode2: this.edit_costcode2,
            old_costcode3: this.edit_costcode3,
            old_optionaldescription: this.edit_optionaldescription,
          });
          this.$http
            .post("/wts/add/cost_code_chain/addCostCode/0", data)
            .then((response) => {
              this.$toasted.show("CostCode edited successfully.", {
                type: "success",
                duration: "3000",
              });
              this.costcode1 = "";
              this.costcode2 = "";
              this.costcode3 = "";
              this.optionaldescription = "";
              this.$refs.editCostCode.hide();
              this.$refs.costCodeTable.refresh();
            })
            .catch((error) => {
              this.$toasted.show("Error.", { type: "error", duration: "3000" });
              this.error = true;
            });
          return;
        }
      });
    },
    editCatValidator(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.pcid,
            projectid: this.projectid,
            status: this.status,
            category: this.category,
            description: this.description,
          });
          this.$http
            .post("/wts/edit/cat", data)
            .then((response) => {
              this.$toasted.show("Category Updated Successfully", {
                type: "success",
                duration: "3000",
              });
              this.$refs.editCat.hide();
              this.loadData();
              this.error = false;
            })
            .catch((error) => {
              this.$toasted.show("Error", { type: "error", duration: "3000" });
              this.error = true;
            });
          return;
        }
      });
    },
    editWellValidate(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.id,
            projectid: this.$route.params.id,
            uwi: this.uwi,
            uwi_2: this.uwi2,
            uwi_3: this.uwi3,
            ln: this.ln,
            lsdsurface: this.surfaceLocation,
            lsdbottom: this.lsdbottom,
            status: this.status,
          });
          this.$http
            .post("/wts/edit/well", data)
            .then((response) => {
              this.$toasted.show("Successfully Edited", {
                icon: "fa-check",
                type: "success",
                duration: "3000",
              });
              this.$refs.editWell.hide();
              this.error = false;
              this.loadData();
            })
            .catch((error) => {
              this.$toasted.show("Unsuccessful Update", {
                type: "error",
                duration: "3000",
              });
              this.error = true;
            });
        }
        return;
      });
    },
    checkForAfe(props, pcid) {
      var pwid = props;
      var test = [];
      var afeData = this.wtsAfeData.filter(function (v, i) {
        return v.pwid === pwid && v.pcid === pcid;
      });

      if (afeData.length > 0) {
        return true;
      }
    },
    loadVendorServices(vid) {
      if (this.wtsVendorList) {
        var cn = this.wtsVendorList.filter((x) => x.vid === vid);
        if (cn.length > 0) {
          this.vcnProp = cn[0].company_name;
        }
      }
      this.vid = vid;
    },
    loadServices() {
      this.$http
        .get("wts/get/services/" + this.$route.params.id)
        .then((response) => {
          this.servicesData = response.data.result;
        })
        .catch((error) => {});
    },
    loadServicesData() {
      return this.$http
        .get("wts/get/services/" + this.$route.params.id)
        .then((response) => {
          var data = response.data.result;
          return data;
        })
        .catch((error) => {});
    },
    loadServicesDataForEstimates() {
      return this.$http
        .get("/wts/get/vas/" + this.project_selected)
        .then((response) => {
          var data = response.data.result;
          return data;
        })
        .catch((error) => {});
    },
    getAssignedServicesList() {
      return this.$http
        .get("/wts/get/assign_services/" + this.$route.params.id)
        .then((response) => {
          var temp = response.data.result;
          temp = temp.filter((o) => o.afenum === this.afenum);
          return temp;
        })
        .catch((error) => {
          //that.$toasted.show('Error loading cost codes.', {type: 'error', duration: '3000'})
        });
    },
    getAssignedServicesListForEstimates() {
      return this.$http
        .get("/wts/get/assign_services/" + this.$route.params.id)
        .then((response) => {
          var temp = response.data.result;
          temp = temp.filter((o) => o.afenum === this.afes.afenum);
          return temp;
        })
        .catch((error) => {
          //that.$toasted.show('Error loading cost codes.', {type: 'error', duration: '3000'})
        });
    },
    getWtsVendorListAll() {
      return this.$axios
        .get("/wts/get/vas/" + this.project_selected)
        .then((response) => {
          var temp = response.data.result;
          return temp;
        })
        .catch((error) => {});
    },
    async loadCostCodeChain() {
      this.chainData = [];

      const that = this;
      const getAssignedServices = await this.getAssignedServicesList();
      const servicesData = await this.loadServicesData();
      const wtsVendorListAll = await this.getWtsVendorListAll();
      const wtsVendorList = this.lodash.uniqBy(wtsVendorListAll, "vid");

      const chain = that.lodash.map(getAssignedServices, function (item) {
        var id = servicesData.findIndex(function (item2, i) {
          return item2.serviceid === item.serviceid;
        });
        item.serviceName = servicesData[id].name;

        const temp = wtsVendorListAll.filter(
          (x) => x.serviceid === item.serviceid
        );
        if (temp.length > 0) {
          const temp2 = wtsVendorList.filter((x) => x.vid === temp[0].vid);

          var company_name = "";
          if (temp2.length > 0) {
            var company_name = temp2[0].company_name;
            item.serviceCompanyName = company_name;
            item.vid = temp2[0].vid;
          }
        }
        return item;
      });

      this.chainData = chain;
    },
    // SERVICES
    addServiceValidator(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          if (this.projectid.projectid == undefined) {
            this.$toasted.show("Please Select Project", {
              type: "error",
              duration: "3000",
            });

            return;
          }
          let data = JSON.stringify({
            projectid: this.projectid.projectid,
            type: this.type,
            name: this.name,
            uom1: this.uom1,
            uom2: this.uom2,
            uom3: this.uom3,
            pu1: this.pu1,
            pu2: this.pu2,
            pu3: this.pu3,
            currency: this.currency,
          });

          this.$http
            .post("/wts/create/service", data)
            .then((response) => {
              let data = JSON.stringify({
                projectid: this.projectid.projectid,
                vid: this.vid,
                serviceid: response.data.id,
              });

              this.assignServiceValidator(data);
            })
            .catch((error) => {
              this.$toasted.show("Error", { type: "error", duration: "3000" });
              this.error = true;
            });
        }
      });
    },
    assignServiceValidator(data) {
      this.$http
        .post("/wts/create/vas", data)
        .then((response) => {
          this.$toasted.show("Service Added Successfully", {
            type: "success",
            duration: "3000",
          });
          this.$refs.addService.hide();
          this.type = "";
          this.name = "";
          this.uom1 = "";
          this.uom2 = "";
          this.uom3 = "";
          this.pu1 = "";
          this.pu2 = "";
          this.pu3 = "";
          this.loadServices();
          this.loadData();
          this.update = Math.random();
          this.error = false;
        })
        .catch((error) => {
          this.$toasted.show("Error", { type: "error", duration: "3000" });
          this.error = true;
        });
    },
    sendUpdate() {
      this.update = Math.random();
    },
    assignWss(email) {
      var temp = this.wtsWssListData.filter((x) => x.email === email);
      let data = JSON.stringify({
        projectid: this.$route.params.id,
        wssid: temp[0].wssid,
      });

      this.$http
        .post("/wts/create/wss_project", data)
        .then((response) => {})
        .catch((error) => {});
    },
    sendEditService(row) {
      this.serviceid = row.serviceid;
      this.name = row.name;
      this.type = row.type;
      this.uom1 = row.uom1;
      this.uom2 = row.uom2;
      this.uom3 = row.uom3;
      this.pu1 = row.pu1;
      this.pu2 = row.pu2;
      this.pu3 = row.pu3;
      this.currency = row.currency;
      this.status = row.status;
    },
    addWssVendor(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let data = JSON.stringify({
            projectid: this.$route.params.id,
            mail: this.email,
            status: this.status,
          });
          this.$http
            .post("/wts/create/email", data)
            .then((response) => {
              this.$toasted.show("Email added successfully!", {
                type: "success",
                duration: "3000",
              });
              this.email = "";
              this.error = false;
              this.$validator.reset();
            })
            .catch((error) => {
              if (error.response.data.code === "CREATION") {
                if (this.status === 1) {
                  this.assignWss(this.email);
                  this.$toasted.show("WSS added successfully!", {
                    type: "success",
                    duration: "3000",
                  });
                  this.$http
                    .get("/wts/get/wss_project/" + this.$route.params.id)
                    .then((response) => {
                      this.wtsWssData = response.data.result;
                    });
                  this.error = false;
                } else {
                  this.error = true;
                }
              } else {
                this.$toasted.show("Error", {
                  type: "error",
                  duration: "3000",
                });
              }
            });
          return;
        }
      });
    },
    getCC1s(code, projectid) {
      var cc1Data = [];
      var cc1 = 0;
      var match = [];

      return this.$axios
        .get("/wts/get/cc1/" + projectid + "/" + this.pwcaid)
        .then((response) => {
          var temp = response.data.result;
          match = temp.filter((o) => o.code === code);

          if (match.length > 0) {
            cc1 = match[0].cconeid;
            return cc1;
          } else {
            return this.createCC1(code, projectid).then((data) => {
              cc1 = data.id;
              return cc1;
            });
          }
        });
    },
    createCC1(code, projectid) {
      let data = JSON.stringify({
        pwcaid: this.pwcaid,
        projectid: projectid,
        code: code,
      });

      return this.$axios.post("/wts/create/cc1/", data).then((response) => {
        return response.data;
      });
    },
    getCC2s(cc1, code, projectid) {
      var cc2Data = [];
      var cc2 = 0;
      var match = [];

      return this.$axios
        .get("/wts/get/cc2/" + projectid + "/afe/ccone/" + cc1)
        .then((response) => {
          var temp = response.data.result;
          match = temp.filter((o) => o.code === code);

          if (match.length === 0) {
            return this.createCC2(cc1, code, projectid).then((data) => {
              cc2 = data.id;
              return cc2;
            });
          }
          if (match.length > 0) {
            cc2 = match[0].cctwoid;
            return cc2;
          }
        });
    },
    createCC2(cconeid, code, projectid) {
      let data = JSON.stringify({
        cconeid: cconeid,
        projectid: projectid,
        code: code,
      });

      return this.$axios.post("/wts/create/cc2/", data).then((response) => {
        return response.data;
      });
    },
    getCC3s(cc2, code, projectid) {
      var cc3Data = [];
      var cc3 = 0;
      var match = [];
      return this.$axios
        .get(
          "/wts/get/cc3/" + projectid + "/afe/" + this.pwcaid + "/cctwo/" + cc2
        )
        .then((response) => {
          if (code === "") {
            cc3 = "";
            return cc3;
          }

          var temp = response.data.result;
          match = temp.filter((o) => o.code === code);

          if (match.length === 0) {
            return this.createCC3(cc2, code, projectid).then((data) => {
              cc3 = data.id;
              return cc3;
            });
          }
          if (match.length > 0) {
            cc3 = match[0].ccthreeid;
            return cc3;
          }
        });
    },
    createCC3(cctwoid, code, projectid) {
      let data = JSON.stringify({
        cctwoid: cctwoid,
        projectid: projectid,
        code: code,
      });

      return this.$axios.post("/wts/create/cc3/", data).then((response) => {
        return response.data;
      });
    },
    loadAfeData() {
      this.wtsAfeData = [];

      this.$http
        .get("/wts/get/afes/" + this.$route.params.id)
        .then((response) => {
          this.wtsAfeData = response.data.result;
        })
        .catch((error) => {});
    },
    loadData() {
      this.error = false;
      this.$http
        .get("/wts/get/projects")
        .then((response) => {
          this.projectData = response.data.result;
          this.projectidForRouter = this.projectData.projectid;
        })
        .catch((error) => {});
      this.$axios
        .get("/wts/get/vas/" + this.project_selected)
        .then((response) => {
          var temp = response.data.result;
          this.wtsVendorListAll = temp;
          this.wtsVendorListData = this.lodash.uniqBy(temp, "vid");
        })
        .catch((error) => {});
      this.$http
        .get("/wts/get/vendors/" + this.project_selected)
        .then((response) => {
          let data = response.data.result;
          this.wtsVendorList = data;
        })
        .catch((error) => {});
      this.$http.get("/wts/get/profile").then((response) => {
        this.$http
          .get(
            "/wts/get/email_list/" +
              response.data.wtsid +
              "/" +
              this.project_selected
          )
          .then((response2) => {
            this.emailList = response2.data.result.registered_emails;
          });
      });
    },
    getVendorCompanyName(vid) {
      if (this.wtsVendorList) {
        var cn = this.wtsVendorList.filter((x) => x.vid === vid);
        if (cn.length > 0) {
          var company_name = cn[0].company_name;
          return company_name;
        }
      }
    },
  },
  mounted: function () {
    // this.loadServices();
    this.loadData();
    this.$http
      .get("/wts/get/profile")
      .then((response) => {
        this.profile = response.data;
      })
      .catch((error) => {});
  },
};
</script>

<style lang="scss">
.edit {
  display: none;
}
.editing .edit {
  display: block;
}
.editing .edit.rt-d-inline {
  display: inline;
}
.editing .view {
  display: block;
}
// .editing .view .edit{
//   display: block;
// }
.green {
  color: #4dbd74;
}
.danger {
  color: #db1616 !important;
}
.well-datepicker {
  input {
    width: 100%;
    font-size: 100%;
    padding: 0.35rem;
  }
}
.rt-multi {
  width: 100% !important;
  .checkboxLayer {
    margin-top: 10px;
    width: 100%;
  }
  .btn-select {
    width: 100% !important;
    display: block !important;
    padding: 6px 40px !important;
  }
}
.disabled-dp {
  input {
    background: #e6e6e6;
  }
}
#dataTable.wellTable {
  table th:nth-child(3),
  table tr:nth-child(3) {
    width: 110px;
  }
}
.dropdown-chev {
  position: absolute;
  top: 11px;
  right: 15px;
}
.vue-simple-suggest.designed .input-wrapper input {
  padding: 7px;
}

.afe-status-table td {
  width: 120px !important;
}
#loading-gif {
  z-index: 9999;
  position: absolute;
  top: 30%;
  right: 50%;
}

#chain-data {
  .VueTables__search-field {
    margin-top: 0.5em;
    label {
      display: none;
    }
  }

  @media screen and (min-width: 1200px) {
    .table-responsive {
      overflow: visibility !important;
    }
  }

  div table thead tr th {
    background-color: #444;
    color: #fff;
    font-size: 1.2em;
  }
  td {
    vertical-align: middle;
  }
  div table thead tr th:first-child {
    //width: 5%;
  }
  .chain-data-child-table {
    tbody {
      //background-color: rgba(63, 233, 38, 0.09);
    }

    .VuePagination {
      margin-top: 2em;
    }

    th {
      background: #7d7d7d !important;
      color: #fff !important;
      font-size: 1em !important;
    }
    th {
      width: inherit !important;
    }
    th:first-child {
      //width: 5% !important;
    }

    .VueTables__child-row > td {
      padding: 0;
    }
  }
  .parent th {
    background: #444;
    color: #fff;
  }

  .checkslots {
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 2px;
    font-size: 4em;
  }
  .table td {
    padding: 0.55rem;
  }
  .VueTables__heading {
    text-align: left;
  }
  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .VueTables__search-field {
    display: flex;
  }
  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: left;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 18px;
    background: #4dbd74;
    display: none !important;
    margin: auto;
    text-align: center;
    padding: 6px !important;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
  }

  .VueTables__child-row-toggler--open {
    line-height: 17px !important;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "\f054";
    font-weight: 400 !important;
    font-family: "Font Awesome 5 Pro" !important;
  }

  .VueTables__child-row-toggler--open::before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 400 !important;
    content: "\f078" !important;
  }
}
.uwiSeperators input {
  margin: 0 7px !important;
  margin-top: -5px !important;
}

// .removeLastTd td:last-child {
//   display:none !important;
// }
.uwiSeperators .input-group-text {
  padding: 7px !important;
  margin-top: -5px !important;
}
.uwiSeperators .input-group-prepend {
  margin-right: 7px !important;
}

// Added for AFE COST
.static {
  margin: 5px;
  padding: 5px;
}

.ccThreeListTable {
  margin-left: 20%;
}

.no-overflow .VueTables__table {
  overflow: visible !important;
}

.m-t-20 {
  margin-top: 20px;
}

.import-table td button {
  margin: 1px;
  padding: 6px;
  width: auto;
}

.cost-codes {
  margin-top: 36px;

  .row {
    .form-group {
      display: flex;
      justify-content: space-between;

      .VueTables__search-field {
        display: flex;
        flex-direction: row;

        label {
          display: inline;
          font-size: large;
          font-weight: bold;
          margin-right: 10px;
          line-height: 15px;
        }
        input {
          display: inline;
          font-size: medium;
        }
      }

      tr {
        margin-right: 1%;
        display: flex;
        justify-content: space-between;
        margin-top: 18px;
      }
    }
  }

  .table-responsive {
    overflow-x: hidden !important;

    table {
      border-bottom: 2px solid black;

      thead {
        tr {
          th:nth-child(1),
          th:nth-child(2),
          th:nth-child(3),
          th:nth-child(5),
          th:nth-child(6),
          th:nth-child(7) {
            width: fit-content !important;
            background-color: #454545 !important;
            vertical-align: middle;
            color: white;
            text-align: center !important;
            font-size: large !important;
            font-weight: lighter !important;
            border-right: 2px solid black !important;
            border-left: 2px solid black !important;
          }

          th:nth-child(4) {
            font-weight: lighter !important;
            font-size: large !important;
            text-align: center !important;
            background-color: #454545 !important;
            width: 18% !important;
            vertical-align: middle;
            padding: 0.75rem !important;
            color: white;
            border-right: 2px solid black !important;
            border-left: 2px solid black !important;
          }
        }
      }

      tbody {
        tr {
          td {
            width: fit-content !important;
            padding: 5px !important;
            vertical-align: middle;
            text-align: center !important;
            border-right: 2px solid black !important;
            border-left: 2px solid black !important;

            button {
              font-size: smaller !important;
              width: 80%;
            }

            #budget-div {
              span {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                a {
                  margin-left: 6px;
                }
                i {
                  background-color: #ffc107;
                  color: white;
                  padding: 4px;
                  color: white;
                  margin-right: 19px;
                }

                input {
                  width: 160%;
                  margin: 0px 9px 0px 0px;
                  border-radius: 5px;
                }

                #budget-btn-save {
                  i {
                    background-color: transparent;
                  }
                  background-color: #0069d9;
                  width: 22%;
                  margin: 0px 15px 0px 0px;
                }

                #budget-btn-danger {
                  width: 22%;
                  margin: 0px 0px 0px 0px;
                }
              }
            }
          }
        }
      }
    }
  }
  .VuePagination {
    nav {
      margin: auto !important;
    }
  }
}

#modal-3 {
  .modal-content {
    min-height: 100vh !important;

    .modal-body {
      .vendor-table {
        .table-responsive {
          overflow-x: hidden !important;
          table {
            thead {
              tr {
                th:nth-child(1) {
                  background-color: white !important;
                  display: none !important;
                }

                th:nth-child(2),
                th:nth-child(3),
                th:nth-child(4),
                th:nth-child(5),
                th:nth-child(6) {
                  width: fit-content !important;
                  background-color: #454545 !important;
                  vertical-align: middle;
                  color: white;
                  font-size: large !important;
                  font-weight: lighter !important;
                }
              }
            }
            tbody {
              .VueTables__row {
                td:nth-child(1) {
                  display: none !important;
                }
              }

              .VueTables__child-row {
                background-color: white !important;
                :hover {
                  background-color: white !important;
                }
                .VueTables--client {
                  margin-top: 24px;

                  .row {
                    .VueTables__search-field {
                      display: flex;
                      flex-direction: row;
                      label {
                        display: inline;
                        font-size: large;
                        font-weight: bold;
                        margin-right: 10px;
                        line-height: 15px;
                      }
                      input {
                        display: inline;
                        font-size: medium;
                      }
                    }
                  }
                  .table-responsive {
                    overflow-x: hidden !important;

                    table {
                      thead {
                        tr {
                          background-color: white !important;

                          :hover {
                            background-color: #454545 !important;
                          }

                          th:nth-child(2) {
                            width: 20px !important;
                          }
                        }
                      }
                      tbody {
                        tr {
                          td:nth-child(2) {
                            width: 20px !important;
                          }
                        }
                      }
                    }
                  }

                  .VuePagination {
                    nav {
                      margin: auto !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.VueTables__child-row-toggler--closed::before {
  content: "\f078" !important;
  font-weight: 400 !important;
  font-family: "Font Awesome 5 Pro" !important;
}

.VueTables__child-row-toggler--open::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400 !important;
  content: "\f077";
}

#search_estimate {
  display: flex;
  button {
    margin-left: 20px;
  }
}
</style>

